import { axiosInstance } from "app/api/axios";

import { LessonsActionTypes } from "app/store/redux/actionTypes";
import {
  getLessonsUrl,
  deleteLessonUrl,
  getLessonbyIdUrl,
  updateLessonUrl,
  getLessonSubscriptionsUrl,
  approveLessonUrl,
  getUnApprovedLessonsUrl,
  getLessonReviewsUrl,
  removeSubscriptionUrl,
} from "app/api/Endpoint";
import { successAlert } from "app/helpers/alerts";

export function setUserSearchText(event) {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.SET_LESSON_SEARCH_TEXT,
      payload: {
        searchText: event.target.value,
      },
    });
  };
}

export const getLessons = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonsUrl(page, limit);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;

        if (data.lessons) {
          getLessonsSuccess(dispatch, data);
        } else {
          getLessonsFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getLessonsFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonsFail = (dispatch, errorMessage) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonsSuccess = (dispatch, data) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSONS_SUCCESS,
    payload: data,
  });
};

export const deleteLesson = (lessonIds, history) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.DELETE_LESSON_START,
    });

    const request = {
      data: { lessonIds },
    };

    const url = deleteLessonUrl();

    axiosInstance
      .delete(url, request)
      .then((res) => {
        let { data, message } = res.data;
        if (data.lessons) {
          deleteLessonSuccess(dispatch, data, message, history, lessonIds);
        } else {
          deleteLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        deleteLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const deleteLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonsActionTypes.DELETE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const deleteLessonSuccess = (dispatch, data, message, history, lessonIds) => {
  dispatch({
    type: LessonsActionTypes.DELETE_LESSON_SUCCESS,
    payload: { lessonIds },
  });
  successAlert(dispatch, message);
  if (history) {
    history.push("/reviewLessonList");
  }
};

export const getLessonbyId = (lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.GET_LESSON_BY_ID_START,
    });
    const url = getLessonbyIdUrl(lessonId);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.lesson) {
          getLessonbyIdSuccess(dispatch, data);
        } else {
          getLessonbyIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getLessonbyIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonbyIdFail = (dispatch, errorMessage) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSON_BY_ID_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonbyIdSuccess = (dispatch, data) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSON_BY_ID_SUCCESS,
    payload: data,
  });
};

export const updateLesson = (lessonId, data, history, isReviewLessonPage) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.UPDATE_LESSON_START,
    });

    let request = data;
    request["lessonId"] = lessonId;

    const url = updateLessonUrl();
    axiosInstance
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          updateLessonSuccess(
            dispatch,
            data,
            res.data.message,
            history,
            isReviewLessonPage
          );
        } else {
          updateLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        updateLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const updateLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonsActionTypes.UPDATE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updateLessonSuccess = (
  dispatch,
  data,
  message,
  history,
  isReviewLessonPage
) => {
  dispatch({
    type: LessonsActionTypes.UPDATE_LESSON_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, message);
  if (!isReviewLessonPage) {
    history.push("/lessonlist");
  }
};

export const getLessonSubscriptions = (page, limit, lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.GET_LESSON_SUBSCRIPTIONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonSubscriptionsUrl(page, limit, lessonId);

    axiosInstance
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getLessonSubscriptionsSuccess(dispatch, data);
        } else {
          getLessonSubscriptionsFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        getLessonSubscriptionsFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonSubscriptionsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonsActionTypes.GET_LESSON_SUBSCRIPTIONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonSubscriptionsSuccess = (dispatch, data) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSON_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  });
};

export const approveLesson = (lessonId, history) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.UPDATE_LESSON_START,
    });

    let request = {
      lessonId,
    };

    const url = approveLessonUrl();
    axiosInstance
      .put(url, request)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          approveLessonSuccess(dispatch, data, res.data.message, history);
        } else {
          approveLessonFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        approveLessonFail(dispatch, "There was an error connection2");
      });
  };
};
const approveLessonFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonsActionTypes.UPDATE_LESSON_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const approveLessonSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: LessonsActionTypes.UPDATE_LESSON_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, message);
  history.push("/reviewLessonList");
};

export const getUnApprovedLessons = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.GET_LESSONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getUnApprovedLessonsUrl(page, limit);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;

        if (data.lessons) {
          getUnApprovedLessonsSuccess(dispatch, data);
        } else {
          getUnApprovedLessonsFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getUnApprovedLessonsFail(dispatch, "There was an error connection2");
      });
  };
};
const getUnApprovedLessonsFail = (dispatch, errorMessage) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getUnApprovedLessonsSuccess = (dispatch, data) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSONS_SUCCESS,
    payload: data,
  });
};

export const getLessonReviews = (page, limit, lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.GET_LESSON_REVIEWS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonReviewsUrl(page, limit, lessonId);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.reviews) {
          getLessonReviewsSuccess(dispatch, data);
        } else {
          getLessonReviewsFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getLessonReviewsFail(dispatch, "There was an error connection2");
      });
  };
};
const getLessonReviewsFail = (dispatch, errorMessage) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSON_REVIEWS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getLessonReviewsSuccess = (dispatch, data) => {
  dispatch({
    type: LessonsActionTypes.GET_LESSON_REVIEWS_SUCCESS,
    payload: data,
  });
};

export const removeSubscription = (studentId, lessonId) => {
  return (dispatch) => {
    dispatch({
      type: LessonsActionTypes.REMOVE_SUBSCRIPTION_START,
    });

    const request = { studentId, lessonId };

    const url = removeSubscriptionUrl();

    axiosInstance
      .put(url, request)
      .then((res) => {
        let { data, message } = res.data;
        if (data) {
          removeSubscriptionSuccess(dispatch, data, message, studentId);
        } else {
          removeSubscriptionFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        removeSubscriptionFail(dispatch, "There was an error connection2");
      });
  };
};
const removeSubscriptionFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: LessonsActionTypes.REMOVE_SUBSCRIPTION_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const removeSubscriptionSuccess = (dispatch, data, message, userId) => {
  dispatch({
    type: LessonsActionTypes.REMOVE_SUBSCRIPTION_SUCCESS,
    payload: { userId },
  });
  successAlert(dispatch, message);
};
