import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";

import { getStudentPaymentTransactions } from "app/store/students/StudentActions";

const AdvancedSearchConfig = ({ studentId }) => {
  const dispatch = useDispatch();

  const {
    payments: paymentsList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.students);
  useEffect(() => {
    dispatch(getStudentPaymentTransactions(1, 10, studentId));
  }, [dispatch, studentId]);

  const handlePageChange = (page) => {
    // dispatch(getStudentPaymentTransactions(1, 10, studentId));
  };

  const headerRows = {
    data: [
      {
        id: "total_amount",
        align: "left",
        disablePadding: false,
        label: "Amount",
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: "Created At",
        sort: true,
        type: "date",
      },
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <ListTable
        headerRows={headerRows}
        data={paymentsList}
        searchText={searchText}
        handlePageChange={handlePageChange}
        dataLength={totalDataCount}
      />
    </LoadingOverlay>
  );
};

export default AdvancedSearchConfig;
