import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Typography,
  Icon as MaterialIcon,
  makeStyles,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import { errorAlert } from "app/helpers/alerts";
import History from "@history";
import Select from "app/main/common/Select";
import { lighterImage } from "app/helpers/imageCompression";
import { isImage, S3ImageDelete, S3ImageUploader } from "app/helpers/utils";
import {
  getStudentById,
  updateStudent,
} from "app/store/students/StudentActions";
import { Languages_list, Professions, Provinces } from "app/helpers/data";
import Avatar from "app/main/common/Avatar";
import CustomPhoneInput from "app/main/common/CustomPhoneInput";
import MultiCheckbox from "app/main/common/MultiCheckbox";
import SubscribedLessonsList from "./components/SubscribedLessonsList";
import PaymentHistory from "./components/PaymentHistory";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function StudentDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(
    ({ EnglishCafe }) => EnglishCafe.students.loading
  );
  const user = useSelector(({ EnglishCafe }) => EnglishCafe.students.user);

  const params = props.match.params;
  const { studentId } = params;

  useEffect(() => {
    if (studentId !== "new") {
      dispatch(getStudentById(studentId));
    }
  }, [dispatch, studentId]);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  const [tabValue, setTabValue] = useState(0);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  const [inputData, setInputData] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  let [languages, setLanguages] = useState(Languages_list);
  let [extraLanguage, setExtraLanguage] = useState("");

  const inputHandler = (event) => {
    let temp = inputData;
    temp[`${event.target.name}`] = event.target.value;
    setInputData({ ...temp });
    let flag = false;
    for (let key in inputData) {
      if (inputData[key] === "" || inputData[key] === []) {
        flag = true;
      }
    }
    setInputTouched(true);
    setIsDisable(flag);
  };

  useEffect(() => {
    let temp = {};
    // if (userType === "Teacher") {
    //   for (let key in user) {
    //     if (
    //       key === "name" ||
    //       key === "surname" ||
    //       key === "phoneNo" ||
    //       key === "province" ||
    //       key === "biography" ||
    //       key === "experiences" ||
    //       key === "aboutClasses" ||
    //       key === "languages" ||
    //       key === "videoLink" ||
    //       key === "image"
    //     )
    //       temp[`${key}`] = user[key];
    //   }
    // } else {
    for (let key in user) {
      if (
        key === "name" ||
        key === "surname" ||
        key === "phoneNo" ||
        key === "province" ||
        key === "languages" ||
        key === "profession" ||
        key === "image" ||
        key === "notes"
      )
        temp[`${key}`] = user[key];
    }
    // }
    setInputData({ ...temp });
  }, [user]);

  const languageChangeHandler = (inputValues) => {
    const inputLanguages = [];
    inputValues.forEach((e) => {
      if (e.value === true) {
        inputLanguages.push(e.label);
      }
    });

    setInputData((prevForm) => ({
      ...prevForm,
      languages: inputLanguages,
    }));
    setInputTouched(true);
  };

  const addExtraLanguageHandler = () => {
    let temp = languages;
    temp.push({ label: extraLanguage, value: true });
    setExtraLanguage("");
    languageChangeHandler([...temp]);
    setLanguages([...temp]);
  };

  useEffect(() => {
    if (user && user.languages) {
      const items = Languages_list;
      const defaultLanguages = Languages_list.map((e) => {
        return e.label;
      });
      const userLanguages = user.languages;
      const newItems = items.map((item) => {
        if (userLanguages.includes(item.label)) {
          return {
            ...item,
            value: true,
          };
        } else {
          return item;
        }
      });
      userLanguages.forEach((e) => {
        if (!defaultLanguages.includes(e)) {
          newItems.push({ label: e, value: true });
        }
      });
      setLanguages(newItems);
    }
  }, [user]);

  const uploadFile = async (event) => {
    let file1 = event.target.files[0];
    if (file1.type.split("/")[0] === "image") {
      if (!isImage(file1.type)) {
        errorAlert(dispatch, "Choosen image file formate is not supported.");
        return;
      }
    } else {
      errorAlert(dispatch, "Choosen file formate is not supported.");
      return;
    }
    if (Math.floor(file1.size / 1048576) > 10) {
      errorAlert(
        dispatch,
        "File size is too big please upload file less then 10MB"
      );
      return;
    }
    file1 = await lighterImage(file1);

    setInputData((prevForm) => ({
      ...prevForm,
      image: file1,
    }));
    setInputTouched(true);
  };

  const setLoading = (loading) => {
    setImageUploading(loading);
  };

  const submitHandler = async () => {
    let data = inputData;
    if (inputData && inputData.image && typeof inputData.image !== "string") {
      S3ImageDelete(user.image, studentId);
      const imageUrl = await S3ImageUploader(
        inputData.image,
        studentId,
        setLoading
      );
      data = {
        ...data,
        image: imageUrl,
      };
    }
    data["userId"] = studentId;
    dispatch(updateStudent(data, History));
  };

  function goBack() {
    // History.push({
    //   pathname: `/lessonList`,
    // });
    History.goBack();
  }

  const previousPage = () => {
    goBack();
  };

  return (
    <LoadingOverlay
      active={loading || imageUploading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          inputData && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <MaterialIcon className="mr-4 text-20">
                      arrow_back
                    </MaterialIcon>
                    Go back
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {studentId ? inputData.name : "New Student"}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">Student Detail</Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={isDisable || !inputTouched}
                  onClick={submitHandler}
                >
                  <MaterialIcon
                    className={clsx(classes.leftIcon, classes.iconSmall)}
                  >
                    save
                  </MaterialIcon>
                  Save Changes
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab className={"h-64 normal-case"} label={"Student Detail"} />
            <Tab
              className={studentId === "new" ? "hidden" : "h-64 normal-case"}
              label={"List of Subscriptions"}
            />
            <Tab
              className={studentId === "new" ? "hidden" : "h-64 normal-case"}
              label={"Payment History"}
            />
          </Tabs>
        }
        content={
          inputData &&
          inputData.name && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <form
                  className={`flex flex-col   `}
                  onSubmit={async (event) => {
                    event.preventDefault();
                    submitHandler();
                  }}
                >
                  <div
                    className={`flex flex-col md:flex-row  md:items-center justify-center`}
                  >
                    <div className="flex flex-col">
                      <div className="mt-16 flex justify-center">
                        <input
                          type="file"
                          id="uploadImage"
                          accept="image/*"
                          className="hidden"
                          value={""}
                          onChange={uploadFile}
                        />
                        <label
                          htmlFor="uploadImage"
                          className="cursor-pointer flex items-center"
                        >
                          {inputData && inputData.image ? (
                            <Avatar
                              uri={
                                typeof inputData.image === "string"
                                  ? inputData.image
                                  : URL.createObjectURL(inputData.image)
                              }
                              name={inputData.name}
                              classNames="w-60 h-60 cursor-pointer"
                            />
                          ) : (
                            <div
                              className={`flex items-center justify-center bg-blue w-60 h-60`}
                              style={{ borderRadius: "100%" }}
                            >
                              <p
                                className={`capitalize  text-3xl p-0 mt-1 text-white`}
                              >
                                {inputData && inputData.name.charAt(0)}
                              </p>
                            </div>
                          )}
                          <p className="underline ml-2">Edit Profile Picture</p>
                        </label>
                      </div>
                      <div className="mt-16">
                        <p className="font-bold text-xs mb-2">Name*</p>
                        <input
                          name="name"
                          required
                          onChange={inputHandler}
                          value={
                            inputData && inputData.name ? inputData.name : ""
                          }
                          type="text"
                          placeholder="Enter Name"
                          style={{ width: !isMobile && "20vw" }}
                          className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                        />
                      </div>
                      <div className="mt-16">
                        <p className="font-bold text-xs mb-2">Surname</p>
                        <input
                          name="surname"
                          required
                          onChange={inputHandler}
                          value={
                            inputData && inputData.surname
                              ? inputData.surname
                              : ""
                          }
                          type="text"
                          placeholder="Enter Surname"
                          style={{ width: !isMobile && "20vw" }}
                          className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                        />
                      </div>

                      <div
                        className="mt-16 w-full md:customEditProfileWidth"
                        style={{ width: !isMobile && "20vw" }}
                      >
                        <p className="font-bold text-xs mb-2">Province*</p>
                        <Select
                          onChange={inputHandler}
                          value={inputData && inputData.province}
                          name="province"
                          options={Provinces}
                        />
                      </div>
                      <div className="mt-16 self-start md:self-stretch">
                        <p className="font-bold text-xs mb-2">Phone Number*</p>
                        <CustomPhoneInput
                          inputStyle={{
                            width: "inherit",
                            height: "3.5rem",
                            fontSize: "13px",
                            paddingLeft: "48px",
                            borderRadius: "5px",
                          }}
                          name="phoneNo"
                          onChange={inputHandler}
                          value={inputData && inputData.phoneNo}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:ml-20">
                      <>
                        <div
                          className="mt-16 w-full md:customEditProfileWidth"
                          style={{ width: !isMobile && "20vw" }}
                        >
                          <p className="font-bold text-xs mb-2">Profession*</p>
                          <Select
                            onChange={inputHandler}
                            value={inputData && inputData.profession}
                            name="profession"
                            options={Professions}
                          />
                        </div>
                        <div
                          className="flex flex-col mt-16 w-full md:customEditProfileWidth pb-2"
                          style={{ width: !isMobile && "20vw" }}
                        >
                          <p className="font-bold text-xs mb-2">Languages*</p>
                          <div className="flex flex-col items-center justify-center">
                            <MultiCheckbox
                              items={languages}
                              setItems={setLanguages}
                              onChange={languageChangeHandler}
                            />

                            <div
                              className="flex w-full md:customEditProfileWidth mt-8 justify-center"
                              style={{ width: !isMobile && "20vw" }}
                            >
                              <input
                                className="bg-white w-60 md:w-7/12 text-center  p-4 rounded-md outline-none"
                                placeholder="Any other Languages"
                                value={extraLanguage}
                                onChange={(event) => {
                                  setExtraLanguage(event.target.value);
                                }}
                                style={{
                                  boxShadow: "0px 2px 15px rgb(0 0 0 / 15%)",
                                }}
                              />
                              <Button
                                className="ml-6 bg-blue text-white hover:text-black"
                                disabled={extraLanguage === ""}
                                onClick={(event) => {
                                  event.preventDefault();
                                  addExtraLanguageHandler();
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-16">
                          <p className="font-bold text-xs mb-2">Notes*</p>
                          <textarea
                            name="notes"
                            required
                            className="bg-white w-full md:customEditProfileWidth p-4 rounded border border-border-gray outline-none"
                            style={{ width: !isMobile && "20vw" }}
                            placeholder="Enter Notes here"
                            value={inputData && inputData.notes}
                            rows={4}
                            onChange={inputHandler}
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </form>
              )}
              {tabValue === 1 && <SubscribedLessonsList studentId={user._id} />}
              {tabValue === 2 && <PaymentHistory studentId={user._id} />}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default StudentDetail;
