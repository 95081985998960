import StudentList from "./StudentList";
import StudentDetail from "./StudentDetail";
export const StudentPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/studentlist",
      component: StudentList,
    },
    {
      path: "/studentDetail/:studentId",
      component: StudentDetail,
    },
  ],
};
