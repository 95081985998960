import React from "react";
import { TableCell } from "@material-ui/core";

const OrderTypeCell = (props) => {
  return (
    <TableCell className="truncate" component="th" scope="row"></TableCell>
  );
};

export default OrderTypeCell;
