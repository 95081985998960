import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";

function CustomDateRangePicker({ dateRange, onChange }) {
  const [focusedInput, setFocusedInput] = useState();
  return (
    <DateRangePicker
      startDateId="startDate"
      endDateId="endDate"
      displayFormat={() => "DD/MM/YYYY"}
      startDate={
        dateRange &&
        dateRange.value &&
        dateRange.value.startDate !== "" &&
        moment(dateRange.value.startDate)
      }
      endDate={
        dateRange &&
        dateRange.value &&
        dateRange.value.endDate !== "" &&
        moment(dateRange.value.endDate)
      }
      onDatesChange={({ startDate, endDate }) => {
        onChange(startDate, endDate);
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => {
        setFocusedInput(focusedInput);
      }}
    />
  );
}

export default CustomDateRangePicker;
