import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";

import Icon from "app/main/common/Icon";
import { getFormattedDate } from "app/helpers/utils";

function MultipleDateSelector({ dates, name, onChange }) {
  const [focused, setFocused] = useState();
  const [date, setDate] = useState();
  const handleChange = (date) => {
    if (date && date._d) {
      const value = dates;
      value.push(getFormattedDate(date._d));
      const event = {
        target: {
          name: "dates",
          value: value,
        },
      };
      onChange(event);
    }
  };
  return (
    <>
      {dates && dates.length > 0 && (
        <div className="mb-2 flex flex-wrap items-center border border-gray border-dashed p-2 w-full">
          {dates.map((date) => {
            return (
              <div
                className="flex items-center p-2 rounded-md bg-black mr-2 mb-1"
                key={date}
              >
                <p className="text-white text-xs">{date}</p>
                <Icon
                  type="closeIcon"
                  classNames="w-5 ml-2 cursor-pointer  p-0"
                  onClick={() => {
                    let newDates = dates.filter((e) => {
                      return e !== date;
                    });
                    const event = {
                      target: {
                        name,
                        value: newDates,
                      },
                    };
                    onChange(event);
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className=" flex w-full">
        <SingleDatePicker
          displayFormat={() => "DD/MM/YYYY"}
          numberOfMonths={1}
          onDateChange={(date) => {
            setDate(date);
            handleChange(date);
          }}
          onFocusChange={({ focused }) => setFocused(focused)}
          focused={focused}
          date={date}
        />
      </div>
      {/* <TextField
        name="dates"
        type="date"
        minDate={new Date()}
        className="w-full md:w-96"
        onChange={handleChange}
      /> */}
    </>
  );
}

export default MultipleDateSelector;
