import React from 'react'
import {TableCell, Checkbox} from "@material-ui/core";

const CheckBoxCell = ({isSelected,onClick,onChange}) => {
    return (
        <TableCell
            className="w-48 px-4 sm:px-12"
            padding="checkbox"
        >
            <Checkbox
            checked={isSelected}
            onClick={onClick}
            onChange={onChange}
            />
        </TableCell>
    )
}

export default CheckBoxCell