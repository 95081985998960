import userPlaceholder from "./icons/userPlaceholder.png";
import homeScreenBackground from "./icons/homeScreenBackground.jpg";
import logo from "./icons/logo.png";
import backArrow from "./icons/backArrow.png";
import facebookIcon from "./icons/facebookIcon.png";
import googleIcon from "./icons/googleIcon.png";
import studentAuthIcon from "./icons/studentAuthIcon.png";
import teacherAuthIcon from "./icons/teacherAuthIcon.png";
import uploadImageIcon from "./icons/uploadImageIcon.png";
import lessonPlaceholder from "./icons/lessonPlaceholder.png";
import star from "./icons/star.png";
import filterIcon from "./icons/filterIcon.png";
import leftCircle from "./icons/leftCircle.png";
import rightCircle from "./icons/rightCircle.png";
import playButton from "./icons/playButton.png";
import visibility from "./icons/visibility.png";
import visibilityOff from "./icons/visibilityOff.png";
import calendarIcon from "./icons/calendarIcon.png";
import languageIcon from "./icons/languageIcon.png";
import downArrow from "./icons/downArrow.png";
import menuIcon from "./icons/menuIcon.png";
import menuIconRed from "./icons/menuIconRed.png";
import logoutIcon from "./icons/logoutIcon.png";
import signupIcon from "./icons/signupIcon.png";
import lessonIcon from "./icons/lessonIcon.png";
import profileIcon from "./icons/profileIcon.png";
import userIcon from "./icons/userIcon.png";
import createAdIcon from "./icons/createAdIcon.png";
import viewAdIcon from "./icons/viewAdIcon.png";
import messageIcon from "./icons/messageIcon.png";
import logoutIcon2 from "./icons/logoutIcon2.png";
import groupClassIcon from "./icons/groupClassIcon.png";
import oneOnOneClassIcon from "./icons/oneOnOneClassIcon.png";
import childrenClassIcon from "./icons/childrenClassIcon.png";
import experienceClassIcon from "./icons/experienceClassIcon.png";
import filledgroupClassIcon from "./icons/filledgroupClassIcon.png";
import filledoneOnOneClassIcon from "./icons/filledoneOnOneClassIcon.png";
import filledchildrenClassIcon from "./icons/filledchildrenClassIcon.png";
import filledexperienceClassIcon from "./icons/filledexperienceClassIcon.png";
import editIcon from "./icons/editIcon.png";
import editIconBlack from "./icons/editIconBlack.png";
import closeIcon from "./icons/closeIcon.png";
import closeIconRed from "./icons/closeIconRed.png";
import closeIconBlack from "./icons/closeIconBlack.png";
import shoppingCartIcon from "./icons/shoppingCartIcon.png";
import calendarIcon2 from "./icons/calendarIcon2.png";
import cityIcon from "./icons/cityIcon.png";
import addressIcon from "./icons/addressIcon.png";
import levelIcon from "./icons/levelIcon.png";
import vimeoIcon from "./icons/vimeoIcon.png";
import youtubeIcon from "./icons/youtubeIcon.png";
import paymentMethodIcon from "./icons/paymentMethodIcon.png";
import creditCardIcon from "./icons/creditCardIcon.png";
import masterCardIcon from "./icons/cards/masterCardIcon.png";
import visaCardIcon from "./icons/cards/visaCardIcon.png";
import otherCardIcon from "./icons/cards/otherCardIcon.png";
import americanExpressCardIcon from "./icons/cards/americanExpressCardIcon.png";
import confirmIcon from "./icons/confirmIcon.png";
import hintIcon from "./icons/hintIcon.png";

export const icons = {
  userPlaceholder,
  homeScreenBackground,
  logo,
  backArrow,
  facebookIcon,
  googleIcon,
  studentAuthIcon,
  teacherAuthIcon,
  uploadImageIcon,
  lessonPlaceholder,
  star,
  filterIcon,
  leftCircle,
  rightCircle,
  playButton,
  visibility,
  visibilityOff,
  calendarIcon,
  languageIcon,
  downArrow,
  menuIcon,
  menuIconRed,
  logoutIcon,
  lessonIcon,
  signupIcon,
  profileIcon,
  userIcon,
  createAdIcon,
  viewAdIcon,
  messageIcon,
  logoutIcon2,
  groupClassIcon,
  oneOnOneClassIcon,
  childrenClassIcon,
  experienceClassIcon,
  filledgroupClassIcon,
  filledoneOnOneClassIcon,
  filledchildrenClassIcon,
  filledexperienceClassIcon,
  editIcon,
  editIconBlack,
  closeIcon,
  closeIconRed,
  closeIconBlack,
  shoppingCartIcon,
  calendarIcon2,
  cityIcon,
  addressIcon,
  levelIcon,
  vimeoIcon,
  youtubeIcon,
  paymentMethodIcon,
  creditCardIcon,
  masterCardIcon,
  visaCardIcon,
  otherCardIcon,
  americanExpressCardIcon,
  confirmIcon,
  hintIcon,
};
