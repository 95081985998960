import S3 from "react-aws-s3";
import moment from "moment";

export const isVideo = (type) => {
  const mimeTypes = [
    "video/mp4",
    "video/x-flv",
    "video/MP2T",
    "	video/3gpp",
    "video/quicktime",
    "	video/x-msvideo",
    "	video/x-ms-wmv",
  ];
  return mimeTypes.includes(type);
};
export const isImage = (type) => {
  const mimeTypes = ["image/gif", "image/jpeg", "image/png"];
  return mimeTypes.includes(type);
};

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  const month = formattedDate.getFullYear();
  const year = formattedDate.getMonth() + 1;
  return month + "/" + year;
};

export const S3ImageUploader = (file, dirname, setLoadingFunction) => {
  const config = {
    bucketName: "englishcafe-production",
    dirName: dirname,
    region: "eu-west-3",
    accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
    secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
  };
  setLoadingFunction(true);
  const ReactS3Client = new S3(config);
  const filename = Date.now() + file.name;

  return new Promise((resolve, reject) => {
    return ReactS3Client.uploadFile(file, filename)
      .then((res) => {
        setLoadingFunction(false);
        return resolve(res.location);
      })
      .catch((error) => {
        setLoadingFunction(false);
        console.log("error", error);
        return reject("Something went Wrong!");
      });
  });
};

export const S3ImageDelete = (filename, dirname) => {
  const config = {
    bucketName: "englishcafe-development",
    dirName: dirname,
    region: "eu-west-2",
    accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
    secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
  };
  const ReactS3Client = new S3(config);
  ReactS3Client.deleteFile(filename)
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
};

export const getFormattedDate = (current_datetime) => {
  let formatted_date =
    current_datetime.getDate() +
    "/" +
    (current_datetime.getMonth() + 1) +
    "/" +
    current_datetime.getFullYear();
  return formatted_date;
};

export const percentageValue = (percent = 80, value) => {
  return parseFloat((percent / 100) * value).toFixed(2);
};

export const getCardImage = (card) => {
  let cardImage;
  switch (card) {
    case "Visa": {
      cardImage = "visaCardIcon";
      break;
    }
    case "MasterCard": {
      cardImage = "masterCardIcon";
      break;
    }
    case "American Express": {
      cardImage = "americanExpressCardIcon";
      break;
    }
    default: {
      cardImage = "otherCard";
      break;
    }
  }

  return cardImage;
};

export const format_Date = (date) => {
  try {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  } catch (ex) {}
};

export const getNewLessonRenewalDate = (date) => {
  const firstRenewalDate = moment(date, "DD/MM/YYYY").add(1, "M").toDate();
  const monthsToAdd = moment(firstRenewalDate).diff(moment(), "months");
  return moment(date, "DD/MM/YYYY").add(monthsToAdd, "M").toDate();
};
