import { axiosInstance } from "app/api/axios";

import { successAlert } from "app/helpers/alerts";
import { StudentsActionTypes } from "app/store/redux/actionTypes";
import {
  getStudentsUrl,
  getStudentByIdUrl,
  updateStudentUrl,
  getStudentSubscriptionsUrl,
  deleteStudentUrl,
  getStudentPaymentTransactionsUrl,
  getFilteredStudentsUrl,
} from "app/api/Endpoint";

export function setUserSearchText(event) {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.SET_STUDENT_SEARCH_TEXT,
      payload: {
        searchText: event.target.value,
      },
    });
  };
}
export const getUsers = (page, limit, isStudent) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.GET_STUDENTS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getStudentsUrl(page, limit, isStudent);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.students) {
          getUsersSuccess(dispatch, data);
        } else {
          getUsersFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getUsersFail(dispatch, "There was an error connection2");
      });
  };
};

const getUsersFail = (dispatch, errorMessage) => {
  dispatch({
    type: StudentsActionTypes.GET_STUDENTS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUsersSuccess = (dispatch, data) => {
  dispatch({
    type: StudentsActionTypes.GET_STUDENTS_SUCCESS,
    payload: data,
  });
};

export const getStudentPaymentTransactions = (page, limit, userId) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.GET_STUDENT_PAYMENTS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getStudentPaymentTransactionsUrl(page, limit, userId);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.payments) {
          getStudentPaymentTransactionsSuccess(dispatch, data);
        } else {
          getStudentPaymentTransactionsFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        getStudentPaymentTransactionsFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};

const getStudentPaymentTransactionsFail = (dispatch, errorMessage) => {
  dispatch({
    type: StudentsActionTypes.GET_STUDENT_PAYMENTS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getStudentPaymentTransactionsSuccess = (dispatch, data) => {
  dispatch({
    type: StudentsActionTypes.GET_STUDENT_PAYMENTS_SUCCESS,
    payload: data,
  });
};

export const getStudentById = (id) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.GET_STUDENT_START,
    });

    const url = getStudentByIdUrl(id);
    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getStudentByIdSuccess(dispatch, data);
        } else {
          getStudentByIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        getStudentByIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getStudentByIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: StudentsActionTypes.GET_STUDENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getStudentByIdSuccess = (dispatch, data) => {
  dispatch({
    type: StudentsActionTypes.GET_STUDENT_SUCCESS,
    payload: data,
  });
};

export const updateStudent = (data, history) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.UPDATE_STUDENT_START,
    });
    const url = updateStudentUrl();

    axiosInstance
      .put(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          updateStudentSuccess(dispatch, data, res.data.message, history);
        } else {
          updateStudentFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        updateStudentFail(dispatch, "There was an error connection2");
      });
  };
};
const updateStudentFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: StudentsActionTypes.UPDATE_STUDENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updateStudentSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: StudentsActionTypes.UPDATE_STUDENT_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, message);
  history.push("/studentList");
};

export const getStudentSubscriptions = (page, limit, userId) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.GET_STUDENT_SUBSCRIPTIONS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getStudentSubscriptionsUrl(page, limit, userId);

    axiosInstance
      .get(url)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          getStudentSubscriptionsSuccess(dispatch, data);
        } else {
          getStudentSubscriptionsFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((error) => {
        getStudentSubscriptionsFail(dispatch, "There was an error connection2");
      });
  };
};
const getStudentSubscriptionsFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: StudentsActionTypes.GET_STUDENT_SUBSCRIPTIONS_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getStudentSubscriptionsSuccess = (dispatch, data) => {
  dispatch({
    type: StudentsActionTypes.GET_STUDENT_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  });
};

export const deleteStudent = (studentIds) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.DELETE_STUDENT_START,
    });

    const request = {
      data: { studentIds },
    };

    const url = deleteStudentUrl();

    axiosInstance
      .delete(url, request)
      .then((res) => {
        let { data, message } = res.data;
        if (data.students) {
          deleteStudentSuccess(dispatch, data, message, studentIds);
        } else {
          deleteStudentFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        deleteStudentFail(dispatch, "There was an error connection2");
      });
  };
};
const deleteStudentFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: StudentsActionTypes.DELETE_STUDENT_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const deleteStudentSuccess = (dispatch, data, message, studentIds) => {
  dispatch({
    type: StudentsActionTypes.DELETE_STUDENT_SUCCESS,
    payload: { studentIds },
  });
  successAlert(dispatch, message);
};

export const getFilteredStudents = (filter) => {
  return (dispatch) => {
    dispatch(setStudentsLoader(true));
    dispatch({
      type: StudentsActionTypes.GET_STUDENTS_START,
      payload: {
        currentPage: 1,
      },
    });
    const url = getFilteredStudentsUrl(filter);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        // if (data.students && data.students.length > 0) {
        //   const worksheet = utils.json_to_sheet(data.students);
        //   const workbook = utils.book_new();
        //   utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //   writeFile(workbook, "FilteredStudents.xlsx");
        // } else {
        //   errorAlert(dispatch, "No result Found");
        // }
        getUsersSuccess(dispatch, data);
        dispatch(setStudentsLoader(false));
      })
      .catch((e) => {
        dispatch(setStudentsLoader(false));
      });
  };
};

export const resetStudents = () => {
  return (dispatch) => {
    getUsersSuccess(dispatch, {
      currentPage: 1,
      students: [],
      totalDataCount: 0,
    });
  };
};

export const setStudentsLoader = (loading) => {
  return (dispatch) => {
    dispatch({
      type: StudentsActionTypes.SET_STUDENT_LOADER,
      payload: loading,
    });
  };
};
