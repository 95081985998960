import React from "react";

export const LoginPageConfig = {
  settings: {
    layout: {
      config: {
        scroll: "content",
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        mode: "fullwidth"
      }
    }
  },
  routes: [
    {
      path: "/login",
      component: React.lazy(() => import("./LoginPage"))
    }
  ]
};
