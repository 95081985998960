import React from "react";

import { icons } from "app/assets";

const Icon = ({ type, classNames, onClick, style }) => {
  return (
    <img
      alt="icon"
      src={icons[type]}
      className={`${classNames}`}
      onClick={onClick}
      style={style}
    />
  );
};

export default Icon;
