import { axiosInstance } from "app/api/axios";

import { AuthActionTypes } from "app/store/redux/actionTypes";
import { User } from "app/models/User";
import { loginUserUrl } from "app/api/Endpoint";
import history from "@history";

export const submitLogin = (user: User) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGIN_USER_START,
    });

    const request = {
      email: user.email,
      password: user.password,
    };

    const url = loginUserUrl();

    axiosInstance
      .post(url, request)
      .then((res) => {
        let { data } = res.data;
        if (
          data.accessToken &&
          data.accessToken !== "undefined" &&
          data._id &&
          data._id !== "undefined"
        ) {
          loginUserSuccess(dispatch, data);
        } else {
          loginUserFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        loginUserFail(dispatch, "There was an error connection2");
      });
  };
};

const loginUserFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.LOGIN_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const loginUserSuccess = (dispatch, data) => {
  axiosInstance.defaults.headers.common["Authorization"] =
    "Bearer " + data.accessToken;
  localStorage.setItem("access_token", data.accessToken);
  localStorage.setItem("userId", data._id);
  dispatch({
    type: AuthActionTypes.LOGIN_USER_SUCCESS,
    payload: data,
  });
  // history.replace({
  //   pathname: "/home",
  // });
  history.replace({
    pathname: "/teacherList",
  });
};

export const userLogout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("userId");
  history.replace({
    pathname: "/login",
  });
};
