import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";
import { getEnrolmentsByTeacherId } from "app/store/teachers/TeacherActions";

const PaymentList = ({ teacherId }) => {
  const dispatch = useDispatch();

  const {
    enrolments: enrolmentsList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.teachers);
  useEffect(() => {
    dispatch(getEnrolmentsByTeacherId(1, 10, teacherId));
  }, [dispatch, teacherId]);

  const handlePageChange = (page) => {
    // dispatch(getEnrolmentsByTeacherId(page, 10, teacherId));
  };

  const headerRows = {
    data: [
      {
        id: "lessonId",
        align: "left",
        disablePadding: false,
        label: "LessonId",
        sort: true,
        type: "text",
      },
      {
        id: "studentId",
        align: "left",
        disablePadding: false,
        label: "StudentId",
        sort: true,
        type: "text",
      },
      {
        id: "total_amount",
        align: "left",
        disablePadding: false,
        label: "Amount",
        sort: true,
        type: "text",
      },
      {
        id: "enrolmentDate",
        align: "left",
        disablePadding: false,
        label: "Enrolment Date",
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <ListTable
        headerRows={headerRows}
        data={enrolmentsList}
        searchText={searchText}
        handlePageChange={handlePageChange}
        dataLength={totalDataCount}
      />
    </LoadingOverlay>
  );
};

export default PaymentList;
