import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";
import { getLessonReviews } from "app/store/lessons/LessonActions";

const LessonReviewList = ({ lessonId }) => {
  const dispatch = useDispatch();

  const {
    reviews: reviewsList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.lessons);

  useEffect(() => {
    dispatch(getLessonReviews(1, 10, lessonId));
  }, [dispatch, lessonId]);

  const handlePageChange = (page) => {
    // dispatch(getLessonReviews(page, 10, lessonId));
  };

  const headerRows = {
    data: [
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: "Email",
        sort: true,
        type: "text",
      },
      {
        id: "rating",
        align: "left",
        disablePadding: false,
        label: "Rating",
        sort: true,
        type: "text",
      },
      {
        id: "description",
        align: "left",
        disablePadding: false,
        label: "Description",
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <ListTable
        headerRows={headerRows}
        data={reviewsList}
        searchText={searchText}
        handlePageChange={handlePageChange}
        dataLength={totalDataCount}
      />
    </LoadingOverlay>
  );
};

export default LessonReviewList;
