import ReviewLessonList from "./ReviewLessonList";
import LessonDetail from "../lesson/LessonDetail";
export const ReviewLessonPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/reviewLessonList",
      component: ReviewLessonList,
    },
    {
      path: "/reviewLessonDetail/:lessonId",
      component: LessonDetail,
    },
  ],
};
