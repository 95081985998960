import React, { useState } from "react";
import {
  TableHead,
  TableSortLabel,
  TableCell,
  TableRow,
  Checkbox,
  Tooltip,
  IconButton,
  Icon,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import * as Actions from "app/store/actions";
import AlertDialog from "../../common/AlertDialog";

const useStyles = makeStyles((theme) => ({
  actionsButtonWrapper: {
    background: theme.palette.background.paper,
  },
}));

function ListTableHead(props) {
  const classes = useStyles(props);
  const [selectedProductsMenu, setSelectedProductsMenu] = useState(null);
  const dispatch = useDispatch();

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  function openSelectedProductsMenu(event) {
    setSelectedProductsMenu(event.currentTarget);
  }

  function closeSelectedProductsMenu(removeItem) {
    removeItem && props.handleDelete();
    setSelectedProductsMenu(null);
  }

  return (
    <TableHead>
      <TableRow className="h-64">
        {props.headerRows.checkbox && (
          <TableCell padding="checkbox" className="relative pl-4 sm:pl-12">
            <Checkbox
              indeterminate={
                props.numSelected > 0 && props.numSelected < props.rowCount
              }
              checked={props.numSelected === props.rowCount}
              onChange={props.onSelectAllClick}
            />
            {props.numSelected > 0 && (
              <div
                className={clsx(
                  "flex items-center justify-center absolute w-64 top-0 left-0 ml-64 h-64 z-10",
                  classes.actionsButtonWrapper
                )}
              >
                <IconButton
                  aria-owns={
                    selectedProductsMenu ? "selectedProductsMenu" : null
                  }
                  aria-haspopup="true"
                  onClick={openSelectedProductsMenu}
                >
                  <Icon>more_horiz</Icon>
                </IconButton>
                <Menu
                  id="selectedProductsMenu"
                  anchorEl={selectedProductsMenu}
                  open={Boolean(selectedProductsMenu)}
                  onClose={() => {
                    closeSelectedProductsMenu(false);
                  }}
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          Actions.openDialog({
                            children: (
                              <AlertDialog
                                onSuccess={closeSelectedProductsMenu}
                                title={"Remove Confirmation"}
                                message={
                                  "Are you sure you want to delete this item?"
                                }
                              />
                            ),
                          })
                        );
                      }}
                    >
                      <ListItemIcon className="min-w-40">
                        <Icon>delete</Icon>
                      </ListItemIcon>
                      <ListItemText primary={"Delete"} />
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            )}
          </TableCell>
        )}
        {props.headerRows.data.map((row) => {
          return (
            <TableCell
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={
                props.order.id === row.id ? props.order.direction : false
              }
            >
              {row.sort ? (
                <Tooltip
                  title="Sort"
                  placement={
                    row.align === "right" ? "bottom-end" : "bottom-start"
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={props.order.id === row.id}
                    direction={props.order.direction}
                    onClick={createSortHandler(row.id)}
                    style={{ marginLeft: props.headerRows.checkbox ? 0 : 15 }}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                row.label
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default ListTableHead;
