export const Provinces = [
  {
    id: "15",
    label: "A Coruña",
    value: "A Coruña",
    latitude: "43.371320",
    longitude: "-8.395810",
  },
  {
    id: "1",
    label: "Álava",
    value: "Álava",
    latitude: "42.846759",
    longitude: "-2.672333",
  },
  {
    id: "2",
    label: "Albacete",
    value: "Albacete",
    latitude: "38.996157",
    longitude: "-1.855802",
  },
  {
    id: "3",
    label: "Alicante",
    value: "Alicante",
    latitude: "38.345202",
    longitude: "-0.481249",
  },
  {
    id: "4",
    label: "Almería",
    value: "Almería",
    latitude: "36.840138",
    longitude: "-2.467638",
  },
  {
    id: "33",
    label: "Asturias",
    value: "Asturias",
    latitude: "43.360547",
    longitude: "-5.844563",
  },
  {
    id: "5",
    label: "Ávila",
    value: "Ávila",
    latitude: "40.656699",
    longitude: "-4.700110",
  },
  {
    id: "6",
    label: "Badajoz",
    value: "Badajoz",
    latitude: "38.878969",
    longitude: "-6.970073",
  },
  {
    id: "8",
    label: "Barcelona",
    value: "Barcelona",
    latitude: "41.382494",
    longitude: "2.177122",
  },
  {
    id: "48",
    label: "Bizkaia",
    value: "Bizkaia",
    latitude: "43.264147",
    longitude: "-2.923452",
  },
  {
    id: "9",
    label: "Burgos",
    value: "Burgos",
    latitude: "42.340755",
    longitude: "-3.702208",
  },
  {
    id: "10",
    label: "Cáceres",
    value: "Cáceres",
    latitude: "39.474523",
    longitude: "-6.371846",
  },
  {
    id: "11",
    label: "Cádiz",
    value: "Cádiz",
    latitude: "36.529707",
    longitude: "-6.292943",
  },
  {
    id: "39",
    label: "Cantabria",
    value: "Cantabria",
    latitude: "43.462203",
    longitude: "-3.810000",
  },
  {
    id: "12",
    label: "Castellón",
    value: "Castellón",
    latitude: "39.986102",
    longitude: "-0.037938",
  },
  {
    id: "51",
    label: "Ceuta",
    value: "Ceuta",
    latitude: "35.8891751",
    longitude: "-5.3885657",
  },
  {
    id: "13",
    label: "Ciudad Real",
    value: "Ciudad Real",
    latitude: "38.984939",
    longitude: "-3.928341",
  },
  {
    id: "14",
    label: "Córdoba",
    value: "Córdoba",
    latitude: "37.871844",
    longitude: "-4.793652",
  },
  {
    id: "16",
    label: "Cuenca",
    value: "Cuenca",
    latitude: "40.066220",
    longitude: "-2.141563",
  },
  {
    id: "20",
    label: "Gipuzkoa",
    value: "Gipuzkoa",
    latitude: "43.321796",
    longitude: "43.321796",
  },
  {
    id: "17",
    label: "Girona",
    value: "Girona",
    latitude: "41.983146",
    longitude: "2.824784",
  },
  {
    id: "18",
    label: "Granada",
    value: "Granada",
    latitude: "37.189980",
    longitude: "-3.612157",
  },
  {
    id: "19",
    label: "Guadalajara",
    value: "Guadalajara",
    latitude: "40.629462",
    longitude: "-3.172230",
  },
  {
    id: "21",
    label: "Huelva",
    value: "Huelva",
    latitude: "37.256649",
    longitude: "-6.949596",
  },
  {
    id: "22",
    label: "Huesca",
    value: "Huesca",
    latitude: "42.140595",
    longitude: "-0.408933",
  },
  {
    id: "7",
    label: "Illes Balears",
    value: "Illes Balears",
    latitude: "39.567443",
    longitude: "2.657091",
  },
  {
    id: "23",
    label: "Jaén",
    value: "Jaén",
    latitude: "37.770782",
    longitude: "-3.793581",
  },
  {
    id: "26",
    label: "La Rioja",
    value: "La Rioja",
    latitude: "42.466494",
    longitude: "-2.439304",
  },
  {
    id: "35",
    label: "Las Palmas",
    value: "Las Palmas",
    latitude: "28.125014",
    longitude: "-15.428562",
  },
  {
    id: "24",
    label: "León",
    value: "León",
    latitude: "42.597552",
    longitude: "-5.574149",
  },
  {
    id: "25",
    label: "Lleida",
    value: "Lleida",
    latitude: "41.615350",
    longitude: "0.622470",
  },
  {
    id: "27",
    label: "Lugo",
    value: "Lugo",
    latitude: "43.010078",
    longitude: "-7.555740",
  },
  {
    id: "28",
    label: "Madrid",
    value: "Madrid",
    latitude: "40.416972",
    longitude: "-3.703550",
  },
  {
    id: "29",
    label: "Málaga",
    value: "Málaga",
    latitude: "36.720256",
    longitude: "-4.414914",
  },
  {
    id: "52",
    label: "Melilla",
    value: "Melilla",
    latitude: "35.2863161",
    longitude: "-2.9620361",
  },
  {
    id: "30",
    label: "Murcia",
    value: "Murcia",
    latitude: "37.983467",
    longitude: "-1.130185",
  },
  {
    id: "31",
    label: "Navarra",
    value: "Navarra",
    latitude: "42.818435",
    longitude: "-1.644134",
  },
  {
    id: "32",
    label: "Ourense",
    value: "Ourense",
    latitude: "42.335562",
    longitude: "-7.863900",
  },
  {
    id: "34",
    label: "Palencia",
    value: "Palencia",
    latitude: "42.011039",
    longitude: "-4.532915",
  },
  {
    id: "36",
    label: "Pontevedra",
    value: "Pontevedra",
    latitude: "42.431615",
    longitude: "-8.646008",
  },
  {
    id: "37",
    label: "Salamanca",
    value: "Salamanca",
    latitude: "40.965377",
    longitude: "-5.663940",
  },
  {
    id: "38",
    label: "Santa Cruz de Tenerife",
    value: "Santa Cruz de Tenerife",
    latitude: "28.469964",
    longitude: "-16.254546",
  },
  {
    id: "40",
    label: "Segovia",
    value: "Segovia",
    latitude: "40.950585",
    longitude: "-4.124214",
  },
  {
    id: "41",
    label: "Sevilla",
    value: "Sevilla",
    latitude: "37.388653",
    longitude: "-5.994497",
  },
  {
    id: "42",
    label: "Soria",
    value: "Soria",
    latitude: "41.763266",
    longitude: "-2.464141",
  },
  {
    id: "43",
    label: "Tarragona",
    value: "Tarragona",
    latitude: "41.117402",
    longitude: "1.254380",
  },
  {
    id: "44",
    label: "Teruel",
    value: "Teruel",
    latitude: "40.343496",
    longitude: "-1.108030",
  },
  {
    id: "45",
    label: "Toledo",
    value: "Toledo",
    latitude: "39.856600",
    longitude: "-4.024984",
  },
  {
    id: "46",
    label: "Valencia",
    value: "Valencia",
    latitude: "39.469911",
    longitude: "-0.377020",
  },
  {
    id: "47",
    label: "Valladolid",
    value: "Valladolid",
    latitude: "41.652551",
    longitude: "-4.728675",
  },
  {
    id: "49",
    label: "Zamora",
    value: "Zamora",
    latitude: "41.503810",
    longitude: "-5.747021",
  },
  {
    id: "50",
    label: "Zaragoza",
    value: "Zaragoza",
    latitude: "41.656063",
    longitude: "-0.877428",
  },
];

export const Countries_List = [
  { value: "Afghanistan", label: "Afghanistan", code: "AF" },
  { value: "land Islands", label: "land Islands", code: "AX" },
  { value: "Albania", label: "Albania", code: "AL" },
  { value: "Algeria", label: "Algeria", code: "DZ" },
  { value: "American Samoa", label: "American Samoa", code: "AS" },
  { value: "AndorrA", label: "AndorrA", code: "AD" },
  { value: "Angola", label: "Angola", code: "AO" },
  { value: "Anguilla", label: "Anguilla", code: "AI" },
  { value: "Antarctica", label: "Antarctica", code: "AQ" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda", code: "AG" },
  { value: "Argentina", label: "Argentina", code: "AR" },
  { value: "Armenia", label: "Armenia", code: "AM" },
  { value: "Aruba", label: "Aruba", code: "AW" },
  { value: "Australia", label: "Australia", code: "AU" },
  { value: "Austria", label: "Austria", code: "AT" },
  { value: "Azerbaijan", label: "Azerbaijan", code: "AZ" },
  { value: "Bahamas", label: "Bahamas", code: "BS" },
  { value: "Bahrain", label: "Bahrain", code: "BH" },
  { value: "Bangladesh", label: "Bangladesh", code: "BD" },
  { value: "Barbados", label: "Barbados", code: "BB" },
  { value: "Belarus", label: "Belarus", code: "BY" },
  { value: "Belgium", label: "Belgium", code: "BE" },
  { value: "Belize", label: "Belize", code: "BZ" },
  { value: "Benin", label: "Benin", code: "BJ" },
  { value: "Bermuda", label: "Bermuda", code: "BM" },
  { value: "Bhutan", label: "Bhutan", code: "BT" },
  { value: "Bolivia", label: "Bolivia", code: "BO" },
  {
    value: "Bosnia and and Herzegovina",
    label: "Bosnia and Herzegovina",
    code: "BA",
  },
  { value: "Botswana", label: "Botswana", code: "BW" },
  { value: "Bouvet Island", label: "Bouvet Island", code: "BV" },
  { value: "Brazil", label: "Brazil", code: "BR" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
    code: "IO",
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam", code: "BN" },
  { value: "Bulgaria", label: "Bulgaria", code: "BG" },
  { value: "Burkina Faso", label: "Burkina Faso", code: "BF" },
  { value: "Burundi", label: "Burundi", code: "BI" },
  { value: "Cambodia", label: "Cambodia", code: "KH" },
  { value: "Cameroon", label: "Cameroon", code: "CM" },
  { value: "Canada", label: "Canada", code: "CA" },
  { value: "Cape Verde", label: "Cape Verde", code: "CV" },
  { value: "Cayman Islands", label: "Cayman Islands", code: "KY" },
  {
    value: "Central African Republic",
    label: "Central African Republic",
    code: "CF",
  },
  { value: "Chad", label: "Chad", code: "TD" },
  { value: "Chile", label: "Chile", code: "CL" },
  { value: "China", label: "China", code: "CN" },
  { value: "Christmas Island", label: "Christmas Island", code: "CX" },
  {
    value: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
    code: "CC",
  },
  { value: "Colombia", label: "Colombia", code: "CO" },
  { value: "Comoros", label: "Comoros", code: "KM" },
  { value: "Congo", label: "Congo", code: "CG" },
  {
    value: "Congo, The Democratic Republic of the",
    label: "Congo, The Democratic Republic of the",
    code: "CD",
  },
  { value: "Cook Islands", label: "Cook Islands", code: "CK" },
  { value: "Costa Rica", label: "Costa Rica", code: "CR" },
  { value: "Cote D'Ivoire", label: "Cote D'Ivoire", code: "CI" },
  { value: "Croatia", label: "Croatia", code: "HR" },
  { value: "Cuba", label: "Cuba", code: "CU" },
  { value: "Cyprus", label: "Cyprus", code: "CY" },
  { value: "Czech Republic", label: "Czech Republic", code: "CZ" },
  { value: "Denmark", label: "Denmark", code: "DK" },
  { value: "Djibouti", label: "Djibouti", code: "DJ" },
  { value: "Dominica", label: "Dominica", code: "DM" },
  { value: "Dominican Republic", label: "Dominican Republic", code: "DO" },
  { value: "Ecuador", label: "Ecuador", code: "EC" },
  { value: "Egypt", label: "Egypt", code: "EG" },
  { value: "El Salvador", label: "El Salvador", code: "SV" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea", code: "GQ" },
  { value: "Eritrea", label: "Eritrea", code: "ER" },
  { value: "Estonia", label: "Estonia", code: "EE" },
  { value: "Ethiopia", label: "Ethiopia", code: "ET" },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
    code: "FK",
  },
  { value: "Faroe Islands", label: "Faroe Islands", code: "FO" },
  { value: "Fiji", label: "Fiji", code: "FJ" },
  { value: "Finland", label: "Finland", code: "FI" },
  { value: "France", label: "France", code: "FR" },
  { value: "French Guiana", label: "French Guiana", code: "GF" },
  { value: "French Polynesia", label: "French Polynesia", code: "PF" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
    code: "TF",
  },
  { value: "Gabon", label: "Gabon", code: "GA" },
  { value: "Gambia", label: "Gambia", code: "GM" },
  { value: "Georgia", label: "Georgia", code: "GE" },
  { value: "Germany", label: "Germany", code: "DE" },
  { value: "Ghana", label: "Ghana", code: "GH" },
  { value: "Gibraltar", label: "Gibraltar", code: "GI" },
  { value: "Greece", label: "Greece", code: "GR" },
  { value: "Greenland", label: "Greenland", code: "GL" },
  { value: "Grenada", label: "Grenada", code: "GD" },
  { value: "Guadeloupe", label: "Guadeloupe", code: "GP" },
  { value: "Guam", label: "Guam", code: "GU" },
  { value: "Guatemala", label: "Guatemala", code: "GT" },
  { value: "Guernsey", label: "Guernsey", code: "GG" },
  { value: "Guinea", label: "Guinea", code: "GN" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau", code: "GW" },
  { value: "Guyana", label: "Guyana", code: "GY" },
  { value: "Haiti", label: "Haiti", code: "HT" },
  {
    value: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
    code: "HM",
  },
  {
    value: "Holy See (Vatican  City State)",
    label: "Holy See (Vatican City State)",
    code: "VA",
  },
  { value: "Honduras", label: "Honduras", code: "HN" },
  { value: "Hong Kong", label: "Hong Kong", code: "HK" },
  { value: "Hungary", label: "Hungary", code: "HU" },
  { value: "Iceland", label: "Iceland", code: "IS" },
  { value: "India", label: "India", code: "IN" },
  { value: "Indonesia", label: "Indonesia", code: "ID" },
  {
    value: "Iran, Islamic Republic Of",
    label: "Iran, Islamic Republic Of",
    code: "IR",
  },
  { value: "Iraq", label: "Iraq", code: "IQ" },
  { value: "Ireland", label: "Ireland", code: "IE" },
  { value: "Isle of Man", label: "Isle of Man", code: "IM" },
  { value: "Israel", label: "Israel", code: "IL" },
  { value: "Italy", label: "Italy", code: "IT" },
  { value: "Jamaica", label: "Jamaica", code: "JM" },
  { value: "Japan", label: "Japan", code: "JP" },
  { value: "Jersey", label: "Jersey", code: "JE" },
  { value: "Jordan", label: "Jordan", code: "JO" },
  { value: "Kazakhstan", label: "Kazakhstan", code: "KZ" },
  { value: "Kenya", label: "Kenya", code: "KE" },
  { value: "Kiribati", label: "Kiribati", code: "KI" },
  {
    value: "Korea, Democratic People'S Republic of",
    label: "Korea, Democratic People'S Republic of",
    code: "KP",
  },
  { value: "Korea, Republic of", label: "Korea, Republic of", code: "KR" },
  { value: "Kuwait", label: "Kuwait", code: "KW" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan", code: "KG" },
  {
    value: "Lao People'S Democratic Republic",
    label: "Lao People'S Democratic Republic",
    code: "LA",
  },
  { value: "Latvia", label: "Latvia", code: "LV" },
  { value: "Lebanon", label: "Lebanon", code: "LB" },
  { value: "Lesotho", label: "Lesotho", code: "LS" },
  { value: "Liberia", label: "Liberia", code: "LR" },
  {
    value: "Libyan Arab Jamahiriya",
    label: "Libyan Arab Jamahiriya",
    code: "LY",
  },
  { value: "Liechtenstein", label: "Liechtenstein", code: "LI" },
  { value: "Lithuania", label: "Lithuania", code: "LT" },
  { value: "Luxembourg", label: "Luxembourg", code: "LU" },
  { value: "Macao", label: "Macao", code: "MO" },
  {
    value: "Macedonia, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
  },
  { value: "Madagascar", label: "Madagascar", code: "MG" },
  { value: "Malawi", label: "Malawi", code: "MW" },
  { value: "Malaysia", label: "Malaysia", code: "MY" },
  { value: "Maldives", label: "Maldives", code: "MV" },
  { value: "Mali", label: "Mali", code: "ML" },
  { value: "Malta", label: "Malta", code: "MT" },
  { value: "Marshall Islands", label: "Marshall Islands", code: "MH" },
  { value: "Martinique", label: "Martinique", code: "MQ" },
  { value: "Mauritania", label: "Mauritania", code: "MR" },
  { value: "Mauritius", label: "Mauritius", code: "MU" },
  { value: "Mayotte", label: "Mayotte", code: "YT" },
  { value: "Mexico", label: "Mexico", code: "MX" },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
    code: "FM",
  },
  { value: "Moldova, Republic of", label: "Moldova, Republic of", code: "MD" },
  { value: "Monaco", label: "Monaco", code: "MC" },
  { value: "Mongolia", label: "Mongolia", code: "MN" },
  { value: "Montenegro", label: "Montenegro", code: "ME" },
  { value: "Montserrat", label: "Montserrat", code: "MS" },
  { value: "Morocco", label: "Morocco", code: "MA" },
  { value: "Mozambique", label: "Mozambique", code: "MZ" },
  { value: "Myanmar", label: "Myanmar", code: "MM" },
  { value: "Namibia", label: "Namibia", code: "NA" },
  { value: "Nauru", label: "Nauru", code: "NR" },
  { value: "Nepal", label: "Nepal", code: "NP" },
  { value: "Netherlands", label: "Netherlands", code: "NL" },
  { value: "Netherlands Antilles", label: "Netherlands Antilles", code: "AN" },
  { value: "New Caledonia", label: "New Caledonia", code: "NC" },
  { value: "New Zealand", label: "New Zealand", code: "NZ" },
  { value: "Nicaragua", label: "Nicaragua", code: "NI" },
  { value: "Niger", label: "Niger", code: "NE" },
  { value: "Nigeria", label: "Nigeria", code: "NG" },
  { value: "Niue", label: "Niue", code: "NU" },
  { value: "Norfolk Island", label: "Norfolk Island", code: "NF" },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    code: "MP",
  },
  { value: "Norway", label: "Norway", code: "NO" },
  { value: "Oman", label: "Oman", code: "OM" },
  { value: "Pakistan", label: "Pakistan", code: "PK" },
  { value: "Palau", label: "Palau", code: "PW" },
  {
    value: "Palestinian Territory,Occupied",
    label: "Palestinian Territory, Occupied",
    code: "PS",
  },
  { value: "Panama", label: "Panama", code: "PA" },
  { value: "Papua New Guinea", label: "Papua New Guinea", code: "PG" },
  { value: "Paraguay", label: "Paraguay", code: "PY" },
  { value: "Peru", label: "Peru", code: "PE" },
  { value: "Philippines", label: "Philippines", code: "PH" },
  { value: "Pitcairn", label: "Pitcairn", code: "PN" },
  { value: "Poland", label: "Poland", code: "PL" },
  { value: "Portugal", label: "Portugal", code: "PT" },
  { value: "Puerto Rico", label: "Puerto Rico", code: "PR" },
  { value: "Qatar", label: "Qatar", code: "QA" },
  { value: "Reunion", label: "Reunion", code: "RE" },
  { value: "Romania", label: "Romania", code: "RO" },
  { value: "Russian Federation", label: "Russian Federation", code: "RU" },
  { value: "RWANDA", label: "RWANDA", code: "RW" },
  { value: "Saint Helena", label: "Saint Helena", code: "SH" },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    code: "KN",
  },
  { value: "Saint Lucia", label: "Saint Lucia", code: "LC" },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
    code: "PM",
  },
  {
    value: "Saint Vincent and and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  { value: "Samoa", label: "Samoa", code: "WS" },
  { value: "San Marino", label: "San Marino", code: "SM" },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    code: "ST",
  },
  { value: "Saudi Arabia", label: "Saudi Arabia", code: "SA" },
  { value: "Senegal", label: "Senegal", code: "SN" },
  { value: "Serbia", label: "Serbia", code: "RS" },
  { value: "Seychelles", label: "Seychelles", code: "SC" },
  { value: "Sierra Leone", label: "Sierra Leone", code: "SL" },
  { value: "Singapore", label: "Singapore", code: "SG" },
  { value: "Slovakia", label: "Slovakia", code: "SK" },
  { value: "Slovenia", label: "Slovenia", code: "SI" },
  { value: "Solomon Islands", label: "Solomon Islands", code: "SB" },
  { value: "Somalia", label: "Somalia", code: "SO" },
  { value: "South Africa", label: "South Africa", code: "ZA" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  { value: "Spain", label: "Spain", code: "ES" },
  { value: "Sri Lanka", label: "Sri Lanka", code: "LK" },
  { value: "Sudan", label: "Sudan", code: "SD" },
  { value: "Suriname", label: "Suriname", code: "SR" },
  {
    value: "Svalbard and Jan Jan Mayen",
    label: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  { value: "Swaziland", label: "Swaziland", code: "SZ" },
  { value: "Sweden", label: "Sweden", code: "SE" },
  { value: "Switzerland", label: "Switzerland", code: "CH" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic", code: "SY" },
  {
    value: "Taiwan, Province of China",
    label: "Taiwan, Province of China",
    code: "TW",
  },
  { value: "Tajikistan", label: "Tajikistan", code: "TJ" },
  {
    value: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
    code: "TZ",
  },
  { value: "Thailand", label: "Thailand", code: "TH" },
  { value: "Timor-Leste", label: "Timor-Leste", code: "TL" },
  { value: "Togo", label: "Togo", code: "TG" },
  { value: "Tokelau", label: "Tokelau", code: "TK" },
  { value: "Tonga", label: "Tonga", code: "TO" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago", code: "TT" },
  { value: "Tunisia", label: "Tunisia", code: "TN" },
  { value: "Turkey", label: "Turkey", code: "TR" },
  { value: "Turkmenistan", label: "Turkmenistan", code: "TM" },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
    code: "TC",
  },
  { value: "Tuvalu", label: "Tuvalu", code: "TV" },
  { value: "Uganda", label: "Uganda", code: "UG" },
  { value: "Ukraine", label: "Ukraine", code: "UA" },
  { value: "United Arab Emirates", label: "United Arab Emirates", code: "AE" },
  { value: "United Kingdom", label: "United Kingdom", code: "GB" },
  { value: "United States", label: "United States", code: "US" },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
    code: "UM",
  },
  { value: "Uruguay", label: "Uruguay", code: "UY" },
  { value: "Uzbekistan", label: "Uzbekistan", code: "UZ" },
  { value: "Vanuatu", label: "Vanuatu", code: "VU" },
  { value: "Venezuela", label: "Venezuela", code: "VE" },
  { value: "Viet Nam", label: "Viet Nam", code: "VN" },
  {
    value: "Virgin Islands,British",
    label: "Virgin Islands, British",
    code: "VG",
  },
  { value: "Virgin Islands,, U.S.", label: "Virgin Islands, U.S.", code: "VI" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna", code: "WF" },
  { value: "Western Sahara", label: "Western Sahara", code: "EH" },
  { value: "Yemen", label: "Yemen", code: "YE" },
  { value: "Zambia", label: "Zambia", code: "ZM" },
  { value: "Zimbabwe", label: "Zimbabwe", code: "ZW" },
];

export const Languages_list = [
  { label: "English", value: false },
  { label: "Spanish", value: false },
  { label: "French", value: false },
  { label: "German", value: false },
  { label: "Italian", value: false },
  { label: "Portuguese", value: false },
  { label: "Japanese", value: false },
  { label: "Arabic", value: false },
  { label: "Chinese", value: false },
  { label: "Russian", value: false },
  { label: "Romanian", value: false },
  { label: "Korean", value: false },
];

export const Professions = [
  { label: "Software Developer", value: "Software Developer" },
  { label: "Engineer", value: "Engineer" },
  { label: "Doctor", value: "Doctor" },
  { label: "Scientist", value: "Scientist" },
];

export const categories = ["group", "one-on-one", "children", "experience"];
