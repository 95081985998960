import { LessonsActionTypes } from "app/store/redux/actionTypes";

const INITIAL_STATE: LessonState = {
  lessons: [{}],
  lessonDetail: {},
  loading: false,
  totalDataCount: 0,
  searchText: "",
  users: [{}],
  reviews: [{}],
};

interface Action {
  payload: any;
  type: string;
}

const StudentReducer = (
  state: LessonState = INITIAL_STATE,
  action: Action
): LessonState => {
  switch (action.type) {
    case LessonsActionTypes.GET_LESSONS_START: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case LessonsActionTypes.GET_LESSONS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalDataCount = 0;
        return {
          ...state,
          lessons: action.payload.lessons,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          lessons: [...state.lessons, ...action.payload.lessons],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case LessonsActionTypes.GET_LESSONS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LessonsActionTypes.DELETE_LESSON_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case LessonsActionTypes.DELETE_LESSON_SUCCESS: {
      const lessons = state.lessons.filter(
        (item) => !action.payload.lessonIds.includes(item._id)
      );
      const totalDeleted = state.lessons.length - lessons.length;
      return {
        ...state,
        lessons,
        totalDataCount: state.totalDataCount - totalDeleted,
        loading: false,
      };
    }

    case LessonsActionTypes.DELETE_LESSON_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LessonsActionTypes.UPDATE_LESSON_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case LessonsActionTypes.UPDATE_LESSON_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case LessonsActionTypes.UPDATE_LESSON_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LessonsActionTypes.GET_LESSON_BY_ID_START: {
      state.lessonDetail = {};
      return {
        ...state,
        loading: true,
      };
    }
    case LessonsActionTypes.GET_LESSON_BY_ID_SUCCESS: {
      return {
        ...state,
        lessonDetail: action.payload.lesson,
        loading: false,
      };
    }

    case LessonsActionTypes.GET_LESSON_BY_ID_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LessonsActionTypes.SET_LESSON_SEARCH_TEXT: {
      return {
        ...state,
        loading: false,
        searchText: action.payload.searchText,
      };
    }

    case LessonsActionTypes.GET_LESSON_SUBSCRIPTIONS_START: {
      if (action.payload.currentPage === 1) {
        state.users = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: false };
      }
    }
    case LessonsActionTypes.GET_LESSON_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.users = [];
        return {
          ...state,
          users: action.payload.users,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          users: [...state.users, ...action.payload.users],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case LessonsActionTypes.GET_LESSON_SUBSCRIPTIONS_FAIL: {
      return { ...state, loading: false };
    }

    case LessonsActionTypes.GET_LESSON_REVIEWS_START: {
      if (action.payload.currentPage === 1) {
        state.reviews = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case LessonsActionTypes.GET_LESSON_REVIEWS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        return {
          ...state,
          reviews: action.payload.reviews,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          reviews: [...state.reviews, ...action.payload.reviews],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case LessonsActionTypes.GET_LESSON_REVIEWS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LessonsActionTypes.REMOVE_SUBSCRIPTION_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case LessonsActionTypes.REMOVE_SUBSCRIPTION_SUCCESS: {
      const users = state.users.map((item) => {
        if (action.payload.userId !== item._id) {
          return item;
        } else {
          item["cancelDate"] = new Date();
          return item;
        }
      });
      const totalDeleted = state.users.length - users.length;
      return {
        ...state,
        users,
        totalDataCount: state.totalDataCount - totalDeleted,
        loading: false,
      };
    }

    case LessonsActionTypes.REMOVE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default StudentReducer;
