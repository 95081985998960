import { TeachersActionTypes } from "app/store/redux/actionTypes";

const INITIAL_STATE: TeacherState = {
  users: [],
  totalDataCount: 0,
  loading: false,
  searchText: "",
  user: {},
  lessons: [{}],
  enrolments: [{}],
};

interface Action {
  payload: any;
  type: string;
}

const TeacherReducer = (
  state: TeacherState = INITIAL_STATE,
  action: Action
): TeacherState => {
  switch (action.type) {
    case TeachersActionTypes.GET_TEACHERS_START: {
      if (action.payload.currentPage === 1) {
        state.users = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case TeachersActionTypes.GET_TEACHERS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.users = [];
        state.totalDataCount = 0;
        return {
          ...state,
          users: action.payload.teachers,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          users: [...state.users, ...action.payload.teachers],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case TeachersActionTypes.GET_TEACHERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case TeachersActionTypes.SET_TEACHER_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case TeachersActionTypes.SET_TEACHER_SEARCH_TEXT: {
      return {
        ...state,
        loading: false,
        searchText: action.payload.searchText,
      };
    }

    case TeachersActionTypes.GET_TEACHER_START: {
      return { ...state, user: {}, loading: true };
    }
    case TeachersActionTypes.GET_TEACHER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }
    case TeachersActionTypes.GET_TEACHER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case TeachersActionTypes.UPDATE_TEACHER_START: {
      return { ...state, loading: true };
    }
    case TeachersActionTypes.UPDATE_TEACHER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case TeachersActionTypes.UPDATE_TEACHER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case TeachersActionTypes.GET_LESSONS_BY_TEACHER_ID_START: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case TeachersActionTypes.GET_LESSONS_BY_TEACHER_ID_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalDataCount = 0;
        return {
          ...state,
          lessons: action.payload.lessons,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          lessons: [...state.lessons, ...action.payload.lessons],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case TeachersActionTypes.GET_LESSONS_BY_TEACHER_ID_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case TeachersActionTypes.GET_ENROLMENTS_BY_TEACHER_ID_START: {
      if (action.payload.currentPage === 1) {
        state.enrolments = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case TeachersActionTypes.GET_ENROLMENTS_BY_TEACHER_ID_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.enrolments = [];
        state.totalDataCount = 0;
        return {
          ...state,
          enrolments: action.payload.enrolments,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          enrolments: [...state.enrolments, ...action.payload.enrolments],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case TeachersActionTypes.GET_ENROLMENTS_BY_TEACHER_ID_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case TeachersActionTypes.DELETE_TEACHER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case TeachersActionTypes.DELETE_TEACHER_SUCCESS: {
      const users = state.users.filter(
        (item) => !action.payload.teacherIds.includes(item._id)
      );
      const totalDeleted = state.users.length - users.length;
      return {
        ...state,
        users,
        totalDataCount: state.totalDataCount - totalDeleted,
        loading: false,
      };
    }

    case TeachersActionTypes.DELETE_TEACHER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default TeacherReducer;
