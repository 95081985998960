import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import history from "@history";
import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";

import { getLessonsByTeacherId } from "app/store/teachers/TeacherActions";

const TeacherLessonsList = ({ teacherId }) => {
  const dispatch = useDispatch();

  const {
    lessons: lessonList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.teachers);
  useEffect(() => {
    dispatch(getLessonsByTeacherId(1, 10, teacherId));
  }, [dispatch, teacherId]);

  const handlePageChange = (page) => {
    // dispatch(getLessonsByTeacherId(page, 10, teacherId));
  };

  const handleClick = (item) => {
    history.push("/lessonDetail/" + item._id);
  };

  const headerRows = {
    data: [
      {
        id: "title",
        align: "left",
        disablePadding: false,
        label: "Title",
        sort: true,
        type: "text",
      },
      {
        id: "type",
        align: "left",
        disablePadding: false,
        label: "Type",
        sort: true,
        type: "text",
      },
      {
        id: "teacherId",
        align: "left",
        disablePadding: false,
        label: "TeacherId",
        sort: true,
        type: "text",
      },
      {
        id: "language",
        align: "left",
        disablePadding: false,
        label: "Language",
        sort: true,
        type: "text",
      },
      {
        id: "classType",
        align: "left",
        disablePadding: false,
        label: "ClassType",
        sort: true,
        type: "text",
      },
      {
        id: "city",
        align: "left",
        disablePadding: false,
        label: "City",
        sort: true,
        type: "text",
      },
      {
        id: "cafe",
        align: "left",
        disablePadding: false,
        label: "Cafe",
        sort: true,
        type: "text",
      },
      {
        id: "level",
        align: "left",
        disablePadding: false,
        label: "Level",
        sort: true,
        type: "text",
      },
      {
        id: "pricePerHour",
        align: "left",
        disablePadding: false,
        label: "Price Per Hour",
        sort: true,
        type: "text",
      },
      {
        id: "finalPrice",
        align: "left",
        disablePadding: false,
        label: "finalPrice",
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: "Created At",
        sort: true,
        type: "date",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <ListTable
        handleClick={handleClick}
        headerRows={headerRows}
        data={lessonList}
        searchText={searchText}
        handlePageChange={handlePageChange}
        dataLength={totalDataCount}
      />
    </LoadingOverlay>
  );
};

export default TeacherLessonsList;
