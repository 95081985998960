export const AuthActionTypes = {
  USER_INFO_START: "USER_INFO_START",
  USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
  USER_INFO_FAIL: "USER_INFO_FAIL",

  CHANGE_LANGUAGE_START: "CHANGE_LANGUAGE_START",
  CHANGE_LANGUAGE_SUCCESS: "CHANGE_LANGUAGE_SUCCESS",
  CHANGE_LANGUAGE_FAIL: "CHANGE_LANGUAGE_FAIL",

  LOGIN_USER_START: "LOGIN_USER_START",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  LOGOUT_USER_START: "LOGOUT_USER_START",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAIL: "LOGOUT_USER_FAIL",

  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  REGISTER_USER_START: "REGISTER_USER_START",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAIL: "REGISTER_USER_FAIL",
};

export const StudentsActionTypes = {
  GET_STUDENTS_START: "GET_STUDENTS_START",
  GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
  GET_STUDENTS_FAIL: "GET_STUDENTS_FAIL",

  SET_STUDENT_LOADER: "SET_STUDENT_LOADER",

  GET_STUDENT_PAYMENTS_START: "GET_STUDENT_PAYMENTS_START",
  GET_STUDENT_PAYMENTS_SUCCESS: "GET_STUDENT_PAYMENTS_SUCCESS",
  GET_STUDENT_PAYMENTS_FAIL: "GET_STUDENT_PAYMENTS_FAIL",

  GET_STUDENT_START: "GET_STUDENT_START",
  GET_STUDENT_SUCCESS: "GET_STUDENT_SUCCESS",
  GET_STUDENT_FAIL: "GET_STUDENT_FAIL",

  UPDATE_STUDENT_START: "UPDATE_STUDENT_START",
  UPDATE_STUDENT_SUCCESS: "UPDATE_STUDENT_SUCCESS",
  UPDATE_STUDENT_FAIL: "UPDATE_STUDENT_FAIL",

  GET_STUDENT_SUBSCRIPTIONS_START: "GET_STUDENT_SUBSCRIPTIONS_START",
  GET_STUDENT_SUBSCRIPTIONS_SUCCESS: "GET_STUDENT_SUBSCRIPTIONS_SUCCESS",
  GET_STUDENT_SUBSCRIPTIONS_FAIL: "GET_STUDENT_SUBSCRIPTIONS_FAIL",

  SET_STUDENT_SEARCH_TEXT: "SET_STUDENT_SEARCH_TEXT",

  DELETE_STUDENT_START: "DELETE_STUDENT_START",
  DELETE_STUDENT_SUCCESS: "DELETE_STUDENT_SUCCESS",
  DELETE_STUDENT_FAIL: "DELETE_STUDENT_FAIL",
};

export const TeachersActionTypes = {
  GET_TEACHERS_START: "GET_TEACHERS_START",
  GET_TEACHERS_SUCCESS: "GET_TEACHERS_SUCCESS",
  GET_TEACHERS_FAIL: "GET_TEACHERS_FAIL",

  SET_TEACHER_LOADER: "SET_TEACHER_LOADER",

  GET_TEACHER_START: "GET_TEACHER_START",
  GET_TEACHER_SUCCESS: "GET_TEACHER_SUCCESS",
  GET_TEACHER_FAIL: "GET_TEACHER_FAIL",

  UPDATE_TEACHER_START: "UPDATE_TEACHER_START",
  UPDATE_TEACHER_SUCCESS: "UPDATE_TEACHER_SUCCESS",
  UPDATE_TEACHER_FAIL: "UPDATE_TEACHER_FAIL",

  GET_LESSONS_BY_TEACHER_ID_START: "GET_LESSONS_BY_TEACHER_ID_START",
  GET_LESSONS_BY_TEACHER_ID_SUCCESS: "GET_LESSONS_BY_TEACHER_ID_SUCCESS",
  GET_LESSONS_BY_TEACHER_ID_FAIL: "GET_LESSONS_BY_TEACHER_ID_FAIL",

  GET_ENROLMENTS_BY_TEACHER_ID_START: "GET_ENROLMENTS_BY_TEACHER_ID_START",
  GET_ENROLMENTS_BY_TEACHER_ID_SUCCESS: "GET_ENROLMENTS_BY_TEACHER_ID_SUCCESS",
  GET_ENROLMENTS_BY_TEACHER_ID_FAIL: "GET_ENROLMENTS_BY_TEACHER_ID_FAIL",

  SET_TEACHER_SEARCH_TEXT: "SET_TEACHER_SEARCH_TEXT",

  DELETE_TEACHER_START: "DELETE_TEACHER_START",
  DELETE_TEACHER_SUCCESS: "DELETE_TEACHER_SUCCESS",
  DELETE_TEACHER_FAIL: "DELETE_TEACHER_FAIL",
};

export const LessonsActionTypes = {
  GET_LESSONS_START: "GET_LESSONS_START",
  GET_LESSONS_SUCCESS: "GET_LESSONS_SUCCESS",
  GET_LESSONS_FAIL: "GET_LESSONS_FAIL",

  GET_LESSON_BY_ID_START: "GET_LESSON_BY_ID_START",
  GET_LESSON_BY_ID_SUCCESS: "GET_LESSON_BY_ID_SUCCESS",
  GET_LESSON_BY_ID_FAIL: "GET_LESSON_BY_ID_FAIL",

  DELETE_LESSON_START: "DELETE_LESSON_START",
  DELETE_LESSON_SUCCESS: "DELETE_LESSON_SUCCESS",
  DELETE_LESSON_FAIL: "DELETE_LESSON_FAIL",

  UPDATE_LESSON_START: "UPDATE_LESSON_START",
  UPDATE_LESSON_SUCCESS: "UPDATE_LESSON_SUCCESS",
  UPDATE_LESSON_FAIL: "UPDATE_LESSON_FAIL",

  GET_LESSON_SUBSCRIPTIONS_START: "GET_LESSON_SUBSCRIPTIONS_START",
  GET_LESSON_SUBSCRIPTIONS_SUCCESS: "GET_LESSON_SUBSCRIPTIONS_SUCCESS",
  GET_LESSON_SUBSCRIPTIONS_FAIL: "GET_LESSON_SUBSCRIPTIONS_FAIL",

  SET_LESSON_SEARCH_TEXT: "SET_LESSON_SEARCH_TEXT",

  GET_LESSON_REVIEWS_START: "GET_LESSON_REVIEWS_START",
  GET_LESSON_REVIEWS_SUCCESS: "GET_LESSON_REVIEWS_SUCCESS",
  GET_LESSON_REVIEWS_FAIL: "GET_LESSON_REVIEWS_FAIL",

  REMOVE_SUBSCRIPTION_START: " REMOVE_SUBSCRIPTION_START",
  REMOVE_SUBSCRIPTION_SUCCESS: " REMOVE_SUBSCRIPTION_SUCCESS",
  REMOVE_SUBSCRIPTION_FAIL: " REMOVE_SUBSCRIPTION_FAIL",
};

export const PaymentsActionTypes = {
  GET_PAYMENTS_START: "GET_PAYMENTS_START",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAIL: "GET_PAYMENTS_FAIL",

  SET_PAYMENT_LOADER: "SET_PAYMENT_LOADER",

  SET_PAYMENT_SEARCH_TEXT: "SET_PAYMENT_SEARCH_TEXT",
};
