import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function QuillEditor(props) {
  const { name, value, placeholder, onChange, className, setDescriptionText } =
    props;

  const handleChange = (val, delta, source, editor) => {
    if (source === "user") {
      const event = {
        target: {
          name,
          value: val,
        },
      };
      const text = editor.getText(val);
      // onChangeText(text);
      onChange(event);
      setDescriptionText(text);
    }
  };

  const modules = {
    toolbar: [
      //   [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
    ],
  };
  const formats = [
    // "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
  ];

  return (
    <ReactQuill
      modules={modules}
      formats={formats}
      //   name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className={className}
    />
  );
}

export default QuillEditor;
