import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  deleteStudent,
  getUsers,
  setUserSearchText,
} from "app/store/students/StudentActions";
import history from "@history";
import { utils, writeFile } from "xlsx";

function StudentStatusBadge({ isPreviousStudent }) {
  return (
    <>
      {isPreviousStudent ? (
        <div className="text-green">Yes</div>
      ) : (
        <div className="text-red">No</div>
      )}
    </>
  );
}

const StudentList = () => {
  const dispatch = useDispatch();

  const {
    users: userList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.students);
  useEffect(() => {
    dispatch(getUsers(1, 10, false));
  }, [dispatch]);

  const onHandleDelete = (selected) => {
    dispatch(deleteStudent(selected));
  };
  const setSearchText = (ev) => {
    dispatch(setUserSearchText(ev));
  };

  const handlePageChange = (page) => {
    // dispatch(getUsers(page, 10));
  };

  const handleClick = (item) => {
    history.push("/studentDetail/" + item._id);
  };

  const headerRows = {
    data: [
      {
        id: "name",
        align: "left",
        disablePadding: false,
        label: "Name",
        sort: true,
        type: "text",
      },
      {
        id: "surname",
        align: "left",
        disablePadding: false,
        label: "Surname",
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: "Email",
        sort: true,
        type: "text",
      },
      {
        id: "phoneNo",
        align: "left",
        disablePadding: false,
        label: "Phone Number",
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: "Created At",
        sort: true,
        type: "date",
      },
      {
        id: "isPreviousStudent",
        align: "left",
        disablePadding: false,
        sort: true,
        type: "custom",
        label: "Previous Student",
        CustomComponent: ({ item }) => (
          <StudentStatusBadge isPreviousStudent={item} />
        ),
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["email", "name", "surname", "phoneNo", "createdAt"],
  };

  const exportDataHandler = () => {
    const data = userList.map(
      ({ name, surname, email, phoneNo, createdAt, isPreviousStudent }) => ({
        name,
        surname,
        email,
        phoneNo,
        createdAt,
        isPreviousStudent,
      })
    );
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "Registered.xlsx");
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <ListHeader
            title="Registered"
            setSearchText={setSearchText}
            searchType={"students"}
            onExport={exportDataHandler}
          />
        }
        content={
          <ListTable
            handleClick={handleClick}
            handleDelete={onHandleDelete}
            headerRows={headerRows}
            data={userList}
            searchText={searchText}
            handlePageChange={handlePageChange}
            dataLength={totalDataCount}
          />
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default StudentList;
