import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";

import {
  Button,
  Icon,
  FormControl,
  Typography,
  Paper,
  Select,
  Input,
  OutlinedInput,
  InputLabel,
} from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { ThemeProvider, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function ListHeader(props) {
  const classes = useStyles();

  const searchText = useSelector(
    ({ EnglishCafe }) => EnglishCafe[props.searchType].searchText
  );
  const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);

  const exportClickHandler = () => {
    props.onExport();
  };

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <FuseAnimate animation="transition.expandIn" delay={300}>
          <Icon className="text-32 mr-0 sm:mr-12">store</Icon>
        </FuseAnimate>
        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography className="hidden sm:flex" variant="h6">
            {props.title}
          </Typography>
        </FuseAnimate>
      </div>

      {props.setSearchText && (
        <div className="flex flex-1 items-center justify-center px-12">
          <ThemeProvider theme={mainTheme}>
            <FuseAnimate animation="transition.slideDownIn" delay={300}>
              <Paper
                className="flex items-center w-full max-w-512 px-8 py-4 rounded-8"
                elevation={1}
              >
                <Icon className="mr-8" color="action">
                  search
                </Icon>

                <Input
                  placeholder={"Search"}
                  className="flex flex-1"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    "aria-label": "Search",
                  }}
                  onChange={(ev) => props.setSearchText(ev)}
                />
              </Paper>
            </FuseAnimate>
          </ThemeProvider>
        </div>
      )}

      {props.selectors &&
        props.selectors.length > 0 &&
        props.selectors.map((selector) => (
          <div className="flex flex-1 items-center justify-center px-12">
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  className="mt-10"
                  htmlFor="outlined-venue-native-simple"
                >
                  {selector.title}
                </InputLabel>
                <Select
                  className="mt-10 mb-16 mr-8"
                  native
                  required
                  style={{ maxHeight: "40px" }}
                  onChange={selector.onChange}
                  input={
                    <OutlinedInput
                      name="province"
                      labelWidth={100}
                      id="outlined-venue-native-simple"
                    />
                  }
                >
                  <option value="0">{selector.firstOption}</option>
                  {selector.values.map((value) => (
                    <option value={value[selector.keys[0]]}>
                      {value[selector.keys[1]]}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </FuseAnimate>
          </div>
        ))}
      {props.createButton && (
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <Button
            component={Link}
            to={props.createUrl}
            className="whitespace-no-wrap"
            variant="contained"
          >
            <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
              add_circle_outline
            </Icon>
            <span className="hidden sm:flex">{props.buttonText}</span>
            <span className="flex sm:hidden">Crear</span>
          </Button>
        </FuseAnimate>
      )}
      {props.onExport && (
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <Button
            className="whitespace-nowrap mx-4"
            variant="contained"
            color="secondary"
            onClick={exportClickHandler}
            startIcon={<Icon className="hidden sm:flex">get_app</Icon>}
          >
            Export
          </Button>
        </FuseAnimate>
      )}
    </div>
  );
}

export default ListHeader;
