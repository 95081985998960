import React from "react";

import { TableCell } from "@material-ui/core";
import { format_Date } from "../../../../helpers/utils";

const DateComparison = ({ data }) => {
  return data.eventType === "1" ? (
    <TableCell>{format_Date(data.dateDay)}</TableCell>
  ) : (
    <TableCell>
      {format_Date(data.dateFrom) + " - " + format_Date(data.dateTo)}
    </TableCell>
  );
};

export default DateComparison;
