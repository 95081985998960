import * as Actions from "app/store/actions";

export const successAlert = (dispatch, message: string) => {
  dispatch(
    Actions.showMessage({
      message: message,
      autoHideDuration: 6000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      variant: "success"
    })
  );
};

export const errorAlert = (dispatch, message: string) => {
  dispatch(
    Actions.showMessage({
      message: message,
      autoHideDuration: 6000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      variant: "error"
    })
  );
};
