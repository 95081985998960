import React from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";

import ImageCell from "./ImageCell";
import CheckBoxCell from "./CheckBoxCell";
import SuccessAndDangerCell from "./SuccessAndDangerCell";
import PriceReduceCell from "./PriceReduceCell";
import IconCell from "./IconCell";
import DateTimeCell from "./DateTimeCell";
import DateCell from "./DateCell";
import MenuTypeCell from "./MenuTypeCell";
import OrderStatusCell from "./OrderStatusCell";
import OrderTypeCell from "./OrderTypeCell";
import DateComparison from "./DateComparison";

const RowComponent = ({
  isSelected,
  headerRows,
  handleCheck,
  handleClick,
  n,
}) => {
  return (
    <TableRow
      className={headerRows.clickable ? "h-64 cursor-pointer" : "h-64"}
      hover={headerRows.clickable}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={n._id}
      selected={isSelected}
      onClick={(event) => headerRows.clickable && handleClick(n)}
    >
      {headerRows.checkbox && (
        <CheckBoxCell
          isSelected={isSelected}
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => handleCheck(event, n._id)}
        />
      )}
      {headerRows.data.map((obj) => {
        switch (obj.type) {
          case "image":
            return <ImageCell src={n.image} alt={n.title} />;

          case "text":
            return <SuccessAndDangerCell n={n} obj={obj} />;

          case "date":
            return <DateCell date={n[obj.id]} />;

          case "time":
            return <DateTimeCell date={n[obj.id]} />;

          case "dateComparison":
            return <DateComparison data={n} />;

          case "reducedPrice":
            return <PriceReduceCell n={n} />;

          case "orderType":
            return <OrderTypeCell orderType={n[obj.id]} />;

          case "orderStatus":
            return <OrderStatusCell orderStatus={n.orderStatus} />;

          case "icon":
            return <IconCell platform={n.platform} />;

          case "bool":
            return <MenuTypeCell n={n} obj={obj} />;
          case "name":
            return (
              <TableCell component="th" scope="row">
                {n[obj.id] && n[obj.id].length > 0 ? n[obj.id][0].name : ""}
              </TableCell>
            );
          case "button":
            return (
              <TableCell component="th" scope="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    obj.onClick(n.userId);
                  }}
                >
                  {obj.buttonText}
                </Button>
              </TableCell>
            );
          case "RemoveSubscriberButton":
            return (
              <TableCell component="th" scope="row">
                {n.subscriptionId &&
                  (!n.cancelDate ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        obj.onClick(n._id);
                      }}
                    >
                      {obj.buttonText}
                    </Button>
                  ) : (
                    <p>User Removed</p>
                  ))}
              </TableCell>
            );
          case "Objtext":
            const objKeys = obj.id.split(".");
            let objectToString = JSON.parse(JSON.stringify(n));
            objKeys.forEach((key) => {
              if (typeof objectToString === "object" && key in objectToString) {
                objectToString = objectToString[key];
              } else {
                objectToString = " ";
              }
            });
            return (
              <TableCell className="truncate" component="th" scope="row">
                {typeof objectToString !== "object" && objectToString}
              </TableCell>
            );

          case "custom":
            const CustomComponent = obj.CustomComponent;
            return (
              <TableCell className="truncate" component="th" scope="row">
                <CustomComponent data={n} item={n[obj.id]} />
              </TableCell>
            );
          default:
            return null;
        }
      })}
    </TableRow>
  );
};

export default RowComponent;
