import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import history from "@history";
import LoadingOverlay from "react-loading-overlay";
import ListTable from "app/main/common/List/ListTable";

import {
  getLessonSubscriptions,
  removeSubscription,
} from "app/store/lessons/LessonActions";

const SubscribedUsersList = ({ lessonId }) => {
  const dispatch = useDispatch();

  const {
    users: usersList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.lessons);
  useEffect(() => {
    dispatch(getLessonSubscriptions(1, 10, lessonId));
  }, [dispatch, lessonId]);

  const handlePageChange = (page) => {
    // dispatch(getLessonSubscriptions(page, 10, lessonId));
  };

  const handleClick = (item) => {
    history.push("/studentDetail/" + item.userId);
  };

  const removeSubscriptionHandler = (userId) => {
    dispatch(removeSubscription(userId, lessonId));
  };

  const headerRows = {
    data: [
      {
        id: "name",
        align: "left",
        disablePadding: false,
        label: "Name",
        sort: true,
        type: "text",
      },
      {
        id: "surname",
        align: "left",
        disablePadding: false,
        label: "Surname",
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: "Email",
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: "Created At",
        sort: true,
        type: "date",
      },
      {
        id: "delete",
        align: "left",
        disablePadding: false,
        label: "Remove Subscription",
        onClick: removeSubscriptionHandler,
        buttonText: "Remove",
        // sort: true,
        type: "RemoveSubscriberButton",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <ListTable
        handleClick={handleClick}
        headerRows={headerRows}
        data={usersList}
        searchText={searchText}
        handlePageChange={handlePageChange}
        dataLength={totalDataCount}
      />
    </LoadingOverlay>
  );
};

export default SubscribedUsersList;
