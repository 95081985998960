import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { MainConfig } from "app/MainConfig";
import { LoginPageConfig } from "app/main/login/LoginPageConfig";
import { TeacherPageConfig } from "app/main/teacher/TeacherConfig";
import { StudentPageConfig } from "app/main/student/StudentConfig";
import { RegisteredPageConfig } from "app/main/registered/RegisteredConfig";
import { LessonPageConfig } from "app/main/lesson/LessonConfig";
import { ReviewLessonPageConfig } from "app/main/review-lesson/ReviewLessonConfig";
import { AdvancedSearchPageConfig } from "app/main/advanced-search/AdvancedSearchConfig";

const routeConfigs = [
  LoginPageConfig,
  MainConfig,
  TeacherPageConfig,
  StudentPageConfig,
  LessonPageConfig,
  ReviewLessonPageConfig,
  RegisteredPageConfig,
  AdvancedSearchPageConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to="/login" />,
  },
];
export default routes;
