import { PaymentsActionTypes } from "app/store/redux/actionTypes";

const INITIAL_STATE: PaymentState = {
  payments: [],
  totalDataCount: 0,
  loading: false,
  searchText: "",
};

interface Action {
  payload: any;
  type: string;
}

const StudentReducer = (
  state: PaymentState = INITIAL_STATE,
  action: Action
): PaymentState => {
  switch (action.type) {
    case PaymentsActionTypes.GET_PAYMENTS_START: {
      return { ...state, payments: [], totalDataCount: 0, loading: true };
    }
    case PaymentsActionTypes.GET_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: action.payload.payments,
        totalDataCount: action.payload.totalDataCount,
        loading: false,
      };
    }
    case PaymentsActionTypes.GET_PAYMENTS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case PaymentsActionTypes.SET_PAYMENT_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case PaymentsActionTypes.SET_PAYMENT_SEARCH_TEXT: {
      return {
        ...state,
        loading: false,
        searchText: action.payload.searchText,
      };
    }

    default: {
      return state;
    }
  }
};

export default StudentReducer;
