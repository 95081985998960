import React from "react";
import { TableCell } from "@material-ui/core";

const OrderStatusCell = (props) => {
  return (
    <TableCell className="truncate" component="th" scope="row"></TableCell>
  );
};

export default OrderStatusCell;
