import React, { Component } from "react";
import { FuseUtils } from "@fuse";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/AppContext";
class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: false,
      routes,
    };
  }

  componentDidMount() {
    if (this.checkUser()) {
      const { history, location } = this.props;
      if (location.pathname === "/") {
        return history.push("/teacherList");
      }
      history.replace(location.pathname);
      // history.push("/home");
    } else {
      this.redirectRoute();
    }
  }

  componentDidUpdate() {
    if (!this.checkUser()) {
      this.redirectRoute();
    }
  }

  checkUser = () => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("userId")
    ) {
      return true;
    } else {
      return false;
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];

    return {
      accessGranted: matched
        ? FuseUtils.hasPermission(matched.route.auth, userRole)
        : true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  redirectRoute() {
    const { history } = this.props;

    history.replace({
      pathname: "/login",
    });
  }

  render() {
    return this.state.accessGranted ? (
      <React.Fragment>{this.props.children}</React.Fragment>
    ) : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps, {})(FuseAuthorization));
