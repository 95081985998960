import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import { Provinces } from "app/helpers/data";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredStudents } from "app/store/students/StudentActions";
import ListTable from "app/main/common/List/ListTable";
import { utils, writeFile } from "xlsx";
import { errorAlert } from "app/helpers/alerts";

function Students() {
  const dispatch = useDispatch();

  const { loading, users } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.students
  );

  const [form, setForm] = useState({
    profession: "",
    province: "",
    language: "",
    isOldStudent: false,
  });

  // useEffect(() => {
  //   dispatch(resetStudents());
  // }, [dispatch]);

  useEffect(() => {
    const payload = {};
    if (form && form.profession) {
      payload["profession"] = form.profession;
    }
    if (form && form.province) {
      payload["province"] = form.province;
    }
    if (form && form.isOldStudent) {
      payload["isOldStudent"] = form.isOldStudent;
    }
    if (form && form.language) {
      payload["language"] = form.language;
    }
    dispatch(getFilteredStudents(payload));
  }, [dispatch, form]);

  const inputHandler = (event) => {
    const prevForm = JSON.parse(JSON.stringify(form));

    prevForm[event.target.name] = event.target.value;
    setForm(prevForm);

    // const payload = {};
    // if (prevForm && prevForm.profession) {
    //   payload["profession"] = prevForm.profession;
    // }
    // if (prevForm && prevForm.province) {
    //   payload["province"] = prevForm.province;
    // }
    // if (prevForm && prevForm.isOldStudent) {
    //   payload["isOldStudent"] = prevForm.isOldStudent;
    // }
    // if (prevForm && prevForm.language) {
    //   payload["language"] = prevForm.language;
    // }
    // dispatch(getFilteredStudents(payload));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (users && users.length > 0) {
      const worksheet = utils.json_to_sheet(users);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "FilteredStudents.xlsx");
    } else {
      errorAlert(dispatch, "No result Found");
    }
  };

  const handlePageChange = (page) => {};

  const handleClick = (item) => {
    History.push("/lessonDetail/" + item._id);
  };

  const headerRows = {
    data: [
      {
        id: "name",
        align: "left",
        disablePadding: false,
        label: "Name",
        sort: true,
        type: "text",
      },
      {
        id: "surname",
        align: "left",
        disablePadding: false,
        label: "Surname",
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: "Email",
        sort: true,
        type: "text",
      },
      {
        id: "province",
        align: "left",
        disablePadding: false,
        label: "Province",
        sort: true,
        type: "text",
      },
      {
        id: "profession",
        align: "left",
        disablePadding: false,
        label: "Profession",
        sort: true,
        type: "text",
      },
      {
        id: "languages",
        align: "left",
        disablePadding: false,
        label: "Languages",
        sort: true,
        type: "text",
      },
      {
        id: "oldStudent",
        align: "left",
        disablePadding: false,
        label: "OldStudent",
        sort: true,
        type: "bool",
      },
    ],
    checkbox: false,
    // clickable: true,
    locationSearch: false,
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <form className="flex flex-col px-12 py-20" onSubmit={handleSubmit}>
        <Card className="flex flex-col py-10 px-10">
          <div className="flex flex-col md:flex-row justify-center items-center">
            {/* <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.profession}
                onChange={inputHandler}
                label="Profession"
                name="profession"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Professions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl> */}
            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                value={form && form.profession}
                onChange={(event) =>
                  inputHandler({
                    target: { name: "profession", value: event.target.value },
                  })
                }
                label="Profession"
                name="profession"
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.province}
                onChange={inputHandler}
                label="Province"
                name="province"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Provinces.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                value={form && form.language}
                onChange={(event) =>
                  inputHandler({
                    target: { name: "language", value: event.target.value },
                  })
                }
                label="Language"
                name="language"
              />
            </FormControl>
            <FormControlLabel
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
              control={
                <Checkbox
                  checked={form && form.isOldStudent}
                  onChange={(event) =>
                    inputHandler({
                      target: {
                        name: "isOldStudent",
                        value: !form.isOldStudent,
                      },
                    })
                  }
                  name="isOldStudent"
                  color="primary"
                />
              }
              label="is Old Student"
            />
            <Button
              type="submit"
              className="ml-6 bg-blue text-white self-center hover:bg-black"
            >
              Export
            </Button>
          </div>
        </Card>
      </form>
      {users.length > 0 && (
        <ListTable
          handleClick={handleClick}
          headerRows={headerRows}
          data={users}
          searchText={""}
          handlePageChange={handlePageChange}
          dataLength={users.length}
        />
      )}
    </LoadingOverlay>
  );
}

export default Students;
