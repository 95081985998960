import { axiosInstance } from "app/api/axios";

import { successAlert } from "app/helpers/alerts";
import { TeachersActionTypes } from "app/store/redux/actionTypes";
import {
  getTeachersUrl,
  getTeacherByIdUrl,
  updateTeacherUrl,
  getLessonsbyTeacherIdUrl,
  getEnrolmentsByTeacherIdUrl,
  deleteTeacherUrl,
  getFilteredTeachersUrl,
} from "app/api/Endpoint";

export function setUserSearchText(event) {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.SET_TEACHER_SEARCH_TEXT,
      payload: {
        searchText: event.target.value,
      },
    });
  };
}
export const getUsers = (page, limit) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.GET_TEACHERS_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getTeachersUrl(page, limit);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.teachers) {
          getUsersSuccess(dispatch, data);
        } else {
          getUsersFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getUsersFail(dispatch, "There was an error connection2");
      });
  };
};

const getUsersFail = (dispatch, errorMessage) => {
  dispatch({
    type: TeachersActionTypes.GET_TEACHERS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUsersSuccess = (dispatch, data) => {
  dispatch({
    type: TeachersActionTypes.GET_TEACHERS_SUCCESS,
    payload: data,
  });
};

export const getTeacherById = (id) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.GET_TEACHER_START,
    });

    const url = getTeacherByIdUrl(id);
    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data) {
          getTeacherByIdSuccess(dispatch, data);
        } else {
          getTeacherByIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        getTeacherByIdFail(dispatch, "There was an error connection2");
      });
  };
};
const getTeacherByIdFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: TeachersActionTypes.GET_TEACHER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getTeacherByIdSuccess = (dispatch, data) => {
  dispatch({
    type: TeachersActionTypes.GET_TEACHER_SUCCESS,
    payload: data,
  });
};

export const updateTeacher = (data, history) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.UPDATE_TEACHER_START,
    });

    const url = updateTeacherUrl();

    axiosInstance
      .put(url, data)
      .then((res) => {
        let { data } = res.data;
        if (data.result) {
          updateTeacherSuccess(dispatch, data, res.data.message, history);
        } else {
          updateTeacherFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        updateTeacherFail(dispatch, "There was an error connection2");
      });
  };
};
const updateTeacherFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: TeachersActionTypes.UPDATE_TEACHER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const updateTeacherSuccess = (dispatch, data, message, history) => {
  dispatch({
    type: TeachersActionTypes.UPDATE_TEACHER_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, message);
  history.push("/teacherList");
};

export const getLessonsByTeacherId = (page, limit, teacherId) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.GET_LESSONS_BY_TEACHER_ID_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getLessonsbyTeacherIdUrl(page, limit, teacherId);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.lessons) {
          getLessonsByTeacherIdSuccess(dispatch, data);
        } else {
          getLessonsByTeacherIdFail(dispatch, "There was an error connection");
        }
      })
      .catch((e) => {
        getLessonsByTeacherIdFail(dispatch, "There was an error connection2");
      });
  };
};

const getLessonsByTeacherIdFail = (dispatch, errorMessage) => {
  dispatch({
    type: TeachersActionTypes.GET_LESSONS_BY_TEACHER_ID_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getLessonsByTeacherIdSuccess = (dispatch, data) => {
  dispatch({
    type: TeachersActionTypes.GET_LESSONS_BY_TEACHER_ID_SUCCESS,
    payload: data,
  });
};

export const getEnrolmentsByTeacherId = (page, limit, teacherId) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.GET_ENROLMENTS_BY_TEACHER_ID_START,
      payload: {
        currentPage: page,
      },
    });
    const url = getEnrolmentsByTeacherIdUrl(page, limit, teacherId);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        if (data.enrolments) {
          getEnrolmentsByTeacherIdSuccess(dispatch, data);
        } else {
          getEnrolmentsByTeacherIdFail(
            dispatch,
            "There was an error connection"
          );
        }
      })
      .catch((e) => {
        getEnrolmentsByTeacherIdFail(
          dispatch,
          "There was an error connection2"
        );
      });
  };
};

const getEnrolmentsByTeacherIdFail = (dispatch, errorMessage) => {
  dispatch({
    type: TeachersActionTypes.GET_ENROLMENTS_BY_TEACHER_ID_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getEnrolmentsByTeacherIdSuccess = (dispatch, data) => {
  dispatch({
    type: TeachersActionTypes.GET_ENROLMENTS_BY_TEACHER_ID_SUCCESS,
    payload: data,
  });
};

export const deleteTeacher = (teacherIds) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.DELETE_TEACHER_START,
    });

    const request = {
      data: { teacherIds },
    };

    const url = deleteTeacherUrl();

    axiosInstance
      .delete(url, request)
      .then((res) => {
        let { data, message } = res.data;
        if (data.teachers) {
          deleteTeacherSuccess(dispatch, data, message, teacherIds);
        } else {
          deleteTeacherFail(dispatch, "There was an error connection");
        }
      })
      .catch((error) => {
        console.log(error.message);
        deleteTeacherFail(dispatch, "There was an error connection2");
      });
  };
};
const deleteTeacherFail = (dispatch, errorMessage) => {
  console.log(errorMessage);
  dispatch({
    type: TeachersActionTypes.DELETE_TEACHER_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const deleteTeacherSuccess = (dispatch, data, message, teacherIds) => {
  dispatch({
    type: TeachersActionTypes.DELETE_TEACHER_SUCCESS,
    payload: { teacherIds },
  });
  successAlert(dispatch, message);
};

export const getFilteredTeachers = (filter) => {
  return (dispatch) => {
    dispatch(setTeachersLoader(true));
    dispatch({
      type: TeachersActionTypes.GET_TEACHERS_START,
      payload: {
        currentPage: 1,
      },
    });
    const url = getFilteredTeachersUrl(filter);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        // if (data.teachers && data.teachers.length) {
        //   const worksheet = utils.json_to_sheet(data.teachers);
        //   const workbook = utils.book_new();
        //   utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //   writeFile(workbook, "FilteredTeachers.xlsx");
        // } else {
        //   errorAlert(dispatch, "No result Found");
        // }
        getUsersSuccess(dispatch, data);
        dispatch(setTeachersLoader(false));
      })
      .catch((e) => {
        dispatch(setTeachersLoader(false));
      });
  };
};

export const resetTeachers = () => {
  return (dispatch) => {
    getUsersSuccess(dispatch, {
      currentPage: 1,
      teachers: [],
      totalDataCount: 0,
    });
  };
};

export const setTeachersLoader = (loading) => {
  return (dispatch) => {
    dispatch({
      type: TeachersActionTypes.SET_TEACHER_LOADER,
      payload: loading,
    });
  };
};
