import React from "react";

function Avatar({ uri, name, classNames, textSize }) {
  return uri ? (
    <img
      alt="userImage"
      className={`${classNames} object-cover`}
      style={{ borderRadius: "100%" }}
      src={uri}
    />
  ) : (
    <div
      className={`flex items-center justify-center bg-blue ${classNames}`}
      style={{ borderRadius: "100%" }}
    >
      <p
        className={`capitalize ${
          textSize ? textSize : "text-3xl"
        } p-0 mt-1 text-white`}
      >
        {name && name.charAt(0)}
      </p>
    </div>
  );
}

export default Avatar;
