import { axiosInstance } from "app/api/axios";

import { PaymentsActionTypes } from "app/store/redux/actionTypes";
import { getFilteredPaymentsUrl } from "app/api/Endpoint";

export function setUserSearchText(event) {
  return (dispatch) => {
    dispatch({
      type: PaymentsActionTypes.SET_PAYMENT_SEARCH_TEXT,
      payload: {
        searchText: event.target.value,
      },
    });
  };
}

export const getFilteredPayments = (filter) => {
  return (dispatch) => {
    dispatch(setPaymentsLoader(true));
    dispatch({
      type: PaymentsActionTypes.GET_PAYMENTS_START,
    });
    const url = getFilteredPaymentsUrl(filter);

    axiosInstance
      .get(url)
      .then((res) => {
        let { data } = res.data;
        // if (data.payments && data.payments.length > 0) {
        //   const worksheet = utils.json_to_sheet(data.payments);
        //   const workbook = utils.book_new();
        //   utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //   writeFile(workbook, "FilteredPayments.xlsx");
        // } else {
        //   errorAlert(dispatch, "No result Found");
        // }
        setPayments(dispatch, data);

        dispatch(setPaymentsLoader(false));
      })
      .catch((e) => {
        dispatch(setPaymentsLoader(false));
      });
  };
};

const setPayments = (dispatch, data) => {
  dispatch({
    type: PaymentsActionTypes.GET_PAYMENTS_SUCCESS,
    payload: data,
  });
};

export const resetPayments = () => {
  return (dispatch) => {
    setPayments(dispatch, { payments: [], totalDataCount: 0 });
  };
};

export const setPaymentsLoader = (loading) => {
  return (dispatch) => {
    dispatch({
      type: PaymentsActionTypes.SET_PAYMENT_LOADER,
      payload: loading,
    });
  };
};
