import React, { useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Typography,
  Icon as MaterialIcon,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";

import History from "@history";
import Teachers from "./components/Teachers";
import Students from "./components/Students";
import Payments from "./components/Payments";

function AdvancedSearch(props) {
  const [tabValue, setTabValue] = useState(0);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function goBack() {
    History.goBack();
  }

  const previousPage = () => {
    goBack();
  };

  return (
    <FusePageCarded
      classes={{
        toolbar: "p-0",
        header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
      }}
      header={
        <div className="flex flex-1 w-full items-center justify-between">
          <div className="flex flex-col items-start max-w-full">
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
              <Button onClick={previousPage}>
                <MaterialIcon className="mr-4 text-20">arrow_back</MaterialIcon>
                Go back
              </Button>
            </FuseAnimate>

            <div className="flex items-center max-w-full">
              <div className="flex flex-col min-w-0">
                <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                  <Typography variant="caption">Advanced Search</Typography>
                </FuseAnimate>
              </div>
            </div>
          </div>
        </div>
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{ root: "w-full h-64" }}
        >
          <Tab className={"h-64 normal-case"} label={"Teachers"} />
          <Tab className={"h-64 normal-case"} label={"Students"} />
          <Tab className={"h-64 normal-case"} label={"Payments"} />
        </Tabs>
      }
      content={
        <div className="p-16 sm:p-24 max-w-2xl">
          {tabValue === 0 && <Teachers />}
          {tabValue === 1 && <Students />}
          {tabValue === 2 && <Payments />}
        </div>
      }
      innerScroll
    />
  );
}

export default AdvancedSearch;
