import {
  Button,
  Card,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import { categories } from "app/helpers/data";
import { getUsers } from "app/store/teachers/TeacherActions";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredPayments } from "app/store/payments/PaymentActions";
import ListTable from "app/main/common/List/ListTable";
import History from "@history";
import { utils, writeFile } from "xlsx";
import { errorAlert } from "app/helpers/alerts";
// import Select from "app/main/common/Select";

function Payments() {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    lessonType: "",
    teacher: "",
  });

  const { users: userList, loading: teacherLoading } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.teachers
  );

  const { loading: paymentLoading, payments } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.payments
  );

  // useEffect(() => {
  //   dispatch(resetPayments());
  // }, [dispatch]);

  useEffect(() => {
    const payload = {};
    if (form && form.teacher) {
      payload["teacher"] = form.teacher;
    }
    if (form && form.lessonType) {
      payload["lessonType"] = form.lessonType;
    }
    dispatch(getFilteredPayments(payload));
  }, [dispatch, form]);

  useEffect(() => {
    dispatch(getUsers(1, 10));
  }, [dispatch]);

  const inputHandler = (event) => {
    const prevForm = JSON.parse(JSON.stringify(form));

    prevForm[event.target.name] = event.target.value;
    setForm(prevForm);

    const payload = {};
    if (form && form.teacher) {
      payload["teacher"] = form.teacher;
    }
    if (form && form.lessonType) {
      payload["lessonType"] = form.lessonType;
    }
    dispatch(getFilteredPayments(payload));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (payments && payments.length > 0) {
      const worksheet = utils.json_to_sheet(payments);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "FilteredPayments.xlsx");
    } else {
      errorAlert(dispatch, "No result Found");
    }
  };

  const handlePageChange = (page) => {};

  const handleClick = (item) => {
    History.push("/lessonDetail/" + item._id);
  };

  const headerRows = {
    data: [
      {
        id: "date",
        align: "left",
        disablePadding: false,
        label: "Date",
        sort: true,
        type: "text",
      },
      {
        id: "type",
        align: "left",
        disablePadding: false,
        label: "Type",
        sort: true,
        type: "text",
      },
      {
        id: "teacherName",
        align: "left",
        disablePadding: false,
        label: "Teacher Name",
        sort: true,
        type: "text",
      },
      {
        id: "teacherSurname",
        align: "left",
        disablePadding: false,
        label: "Teacher Surname",
        sort: true,
        type: "text",
      },
      {
        id: "amount",
        align: "left",
        disablePadding: false,
        label: "Amount",
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    // clickable: true,
    locationSearch: false,
  };

  return (
    <LoadingOverlay
      active={teacherLoading || paymentLoading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: teacherLoading || paymentLoading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <form className="flex flex-col px-12 py-20" onSubmit={handleSubmit}>
        <Card className="flex flex-col py-10 px-10">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.lessonType}
                onChange={inputHandler}
                label="Lesson Type"
                name="lessonType"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {categories.map((item) => (
                  <MenuItem key={item} value={item} className="capitalize">
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.teacher}
                onChange={inputHandler}
                label="Teacher"
                name="teacher"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {userList.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.email}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <Button
              type="submit"
              className="ml-6 bg-blue text-white self-center hover:bg-black"
            >
              Export
            </Button>
          </div>
        </Card>
      </form>
      {payments.length > 0 && (
        <ListTable
          handleClick={handleClick}
          headerRows={headerRows}
          data={payments}
          searchText={""}
          handlePageChange={handlePageChange}
          dataLength={payments.length}
        />
      )}
    </LoadingOverlay>
  );
}

export default Payments;
