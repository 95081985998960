import React, { useState } from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry,drawing,places`,
    loadingElement: <div className="h-full" />,
    containerElement: <div className="h-full" />,
    mapElement: <div className="h-96 border border-border-gray rounded-md" />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {
        marker: null,
      };

      this.setState({
        position: null,
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },

        onPositionChanged: (setInputData) => {
          const position = refs.marker.getPosition();
          setInputData((prevFrom) => ({
            ...prevFrom,
            coordinates: {
              value: {
                latitude: position.lat(),
                longitude: position.lng(),
              },
            },
          }));
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [draggable, setDraggable] = useState(true);
  return (
    <GoogleMap
      zoom={props.zoom && props.zoom}
      defaultZoom={0}
      defaultCenter={{ lat: 17.308688, lng: 7.03125 }}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        draggable: true,
      }}
      center={{
        lat: draggable && parseFloat(props.latitude),
        lng: draggable && parseFloat(props.longitude),
      }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{
            lat: parseFloat(props.latitude),
            lng: parseFloat(props.longitude),
          }}
          draggable={true}
          ref={props.onMarkerMounted}
          onDragStart={() => setDraggable(false)}
          onDragEnd={() => setDraggable(true)}
          onPositionChanged={() => props.onPositionChanged(props.setInputData)}
        />
      )}
    </GoogleMap>
  );
});

export default Map;
