import { User } from "app/models/User";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_START = "LOGIN_START";

export const submitLogin = (user: User) => {
  return dispatch => {
    dispatch({
      type: LOGIN_START
    });
  };
};
