import { AuthActionTypes } from "app/store/redux/actionTypes";

const INITIAL_STATE: AuthState = {
  user: {},
  success: true,
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const AuthReducer = (
  state: AuthState = INITIAL_STATE,
  action: Action
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        success: true,
      };
    }

    case AuthActionTypes.LOGIN_USER_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    case AuthActionTypes.CHANGE_PASSWORD_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.LOGOUT_USER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.LOGOUT_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.REGISTER_USER_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
    case AuthActionTypes.REGISTER_USER_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default AuthReducer;
