import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Tooltip,
  Button,
  Tab,
  Tabs,
  Typography,
  Icon as MaterialIcon,
  makeStyles,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import Geocode from "react-geocode";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import { errorAlert } from "app/helpers/alerts";
import History from "@history";
import {
  approveLesson,
  deleteLesson,
  getLessonbyId,
  updateLesson,
} from "app/store/lessons/LessonActions";
import {
  DayOfTheWeek,
  Level,
  OptionNumberGenerator,
  Discounts,
  LessonInputData,
} from "./helper/Data";
import CustomDateRangePicker from "app/main/common/CustomDateRangePicker";
import MultipleDateSelector from "app/main/common/MultipleDateSelector";
import Icon from "app/main/common/Icon";
import Select from "app/main/common/Select";
import { lighterImage } from "app/helpers/imageCompression";
import {
  isImage,
  percentageValue,
  S3ImageDelete,
  S3ImageUploader,
} from "app/helpers/utils";
import Map from "./components/Map";
// import { Languages_list } from "app/helpers/data";
import SubscribedUsersList from "./components/SubscribedUsersList";
import LessonReviewList from "./components/LessonReviewList";
import QuillEditor from "../common/List/components/QuillEditor";
import { getTeacherById } from "app/store/teachers/TeacherActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

function LessonDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(({ EnglishCafe }) => EnglishCafe.lessons.loading);
  const lesson = useSelector(
    ({ EnglishCafe }) => EnglishCafe.lessons.lessonDetail
  );

  const params = props.match.params;
  const { lessonId } = params;

  const pathName = window.location.pathname.split("/");
  const isReviewLessonPage = pathName[1] === "reviewLessonDetail";

  useEffect(() => {
    if (lessonId !== "new") {
      dispatch(getLessonbyId(lessonId));
    }
  }, [dispatch, lessonId]);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);
  const isMobile = width <= 600;

  const [tabValue, setTabValue] = useState(0);

  const [isDisable, setIsDisable] = useState(true);
  const [imageUploading, setImageUploading] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [toBeDeletedImages, setToBeDeletedImages] = useState([]);
  const [mapsZoom, setMapsZoom] = useState(2);
  const [descriptionText, setDescriptionText] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);

  const {
    form,
    //  handleChange,
    setForm,
  } = useForm({ ...LessonInputData });
  const {
    // form: initialForm,
    // handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);

  useEffect(() => {
    if (lessonId && lesson) {
      let temp = {
        type: {
          value: "group",
        },
        language: {
          value: "",
        },
        classType: {
          value: "",
        },
        city: {
          value: "",
        },
        postalCode: {
          value: "",
        },
        address: {
          value: "",
        },
        coordinates: {
          value: { latitude: 17.308688, longitude: 7.03125 },
        },
        title: {
          value: "",
        },
        description: {
          value: "",
        },
        level: {
          value: [],
        },
        duration: {
          value: "",
        },
        startTime: {
          value: "",
        },
        endTime: {
          value: "",
        },
        dayOfTheWeek: {
          value: "",
        },
        dateRange: {
          value: { startDate: "", endDate: "" },
        },
        dates: {
          value: [],
        },
        numberOfPlaces: {
          value: "",
        },
        trialClass: {
          value: "",
        },
        price: {
          value: "",
        },
        discount: {
          value: "",
        },
        imageFiles: {
          value: [],
        },
      };
      for (let key in temp) {
        if (key === "imageFiles") {
          temp[key].value = lesson["images"];
        } else {
          temp[key].value = lesson[key];
        }
      }
      setForm({ ...temp });
      setInitialForm({ ...temp });
      const span = document.createElement("span");
      span.innerHTML = lesson.description;
      const descText = span.innerText || span.textContent;
      setDescriptionText(descText);
      setMapsZoom(12);
    }
  }, [lesson, lessonId, setForm, setInitialForm]);

  useEffect(() => {
    if (!form.city.value) {
      setForm((prevForm) => ({
        ...prevForm,
        city: {
          value: "",
        },
        postalCode: {
          value: "",
        },
        address: {
          value: "",
        },
        coordinates: {
          value: { latitude: 17.308688, longitude: 7.03125 },
        },
      }));
    }
  }, [form.city.value, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  const submitHandler = async () => {
    try {
      let imageUrl = [];

      if (toBeDeletedImages && toBeDeletedImages.length > 0) {
        toBeDeletedImages.forEach((imgUrl) => {
          S3ImageDelete(imgUrl, localStorage.getItem("userId"));
        });
      }

      if (form && form.imageFiles && form.imageFiles.value.length > 0) {
        for (let i = 0; i < form.imageFiles.value.length; i++) {
          if (typeof form.imageFiles.value[i] === "string") {
            imageUrl.push(form.imageFiles.value[i]);
          } else {
            let url = await S3ImageUploader(
              form.imageFiles.value[i],
              localStorage.getItem("userId"),
              setLoading
            );
            imageUrl.push(url);
          }
        }
      }
      let data = {};
      data["type"] = form.type.value;
      data["language"] = form.language.value;
      data["classType"] = form.classType.value;
      data["title"] = form.title.value;
      data["description"] = form.description.value;
      data["price"] = form.price.value;
      data["imageFiles"] = imageUrl;
      if (form.type.value === "group") {
        data["level"] = form.level.value;
        data["dayOfTheWeek"] = form.dayOfTheWeek.value;
        data["dateRange"] = form.dateRange.value;
        data["startTime"] = form.startTime.value;
        data["duration"] = form.duration.value;
        if (form.discount.value !== "") {
          data["discount"] = form.discount.value;
        }
        data["numberOfPlaces"] = 5;
      } else if (
        form.type.value === "one-on-one" ||
        form.type.value === "children"
      ) {
        data["trialClass"] = form.trialClass.value;
      } else if (form.type.value === "experience") {
        data["level"] = form.level.value;
        data["dates"] = form.dates.value;
        data["startTime"] = form.startTime.value;
        data["endTime"] = form.endTime.value;
        data["numberOfPlaces"] = form.numberOfPlaces.value;
      }
      if (form.classType.value === "face-to-face") {
        if (form.type.value === "group" || form.type.value === "experience") {
          data["city"] = form.city.value;
          data["postalCode"] = form.postalCode.value;
          data["address"] = form.address.value;
          data["coordinates"] = form.coordinates.value;
        } else {
          data["city"] = form.city.value;
        }
      }

      dispatch(updateLesson(lessonId, data, History, isReviewLessonPage));
    } catch (error) {
      console.log("error", error);
      errorAlert(dispatch, "Something went wrong");
    }
  };

  const setLoading = (loading) => {
    setImageUploading(loading);
  };

  const dateRangeHandler = (startDate, endDate) => {
    setForm((prevForm) => ({
      ...prevForm,
      dateRange: {
        value: {
          startDate: startDate && startDate._d,
          endDate: endDate && endDate._d,
        },
      },
    }));
    setInputChanged(true);
  };

  const setLatAndLong = (event, query) => {
    Geocode.fromAddress(query).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setForm((prevForm) => ({
          ...prevForm,
          coordinates: {
            value: { latitude: lat, longitude: lng },
          },
        }));
      },
      (error) => {
        console.error(error);
      }
    );
    inputHandler(event);
  };

  const locationChangeHandler = (event) => {
    const query = [event.target.value];

    switch (event.target.name) {
      case "city":
        setMapsZoom(8);
        if (form && form.postalCode && form.postalCode.value !== "") {
          query.push(form.postalCode.value);
        }
        if (form && form.address && form.address.value !== "") {
          query.push(form.address.value);
        }
        break;
      case "postalCode":
        setMapsZoom(11);
        if (form && form.city && form.city.value !== "") {
          query.push(form.city.value);
        }
        if (form.address.value && form.address && form.address.value !== "") {
          query.push(form.address.value);
        }
        break;
      case "address":
        if (form && form.city && form.city.value !== "") {
          query.push(form.city.value);
        }
        if (form && form.postalCode && form.postalCode.value !== "") {
          query.push(form.postalCode.value);
        }
        setMapsZoom(15);
        break;
      default:
        setMapsZoom(2);
    }
    setLatAndLong(event, query.join(" "));
  };

  const inputHandler = (event) => {
    let temp = form;
    temp[`${event.target.name}`] = {
      value: event.target.value,
    };
    setForm({ ...temp });
    setInputChanged(true);
  };

  const uploadFile = async (event) => {
    let tempAttachments = [];
    const totalImagesLength =
      form.imageFiles.value.length + event.target.files.length;
    if (event.target.files.length > 10 || totalImagesLength > 10) {
      errorAlert(dispatch, "You can upload maximum of 10 images");
      return;
    }

    for (let i = 0; i < event.target.files.length; i++) {
      let file1 = event.target.files[i];
      if (file1.type.split("/")[0] === "image") {
        if (!isImage(file1.type)) {
          errorAlert(
            dispatch,
            "Choosen image file or files formate is not supported."
          );
          return;
        }
      } else {
        errorAlert(dispatch, "Choosen file or files formate is not supported.");
        return;
      }
      if (Math.floor(file1.size / 1048576) > 10) {
        errorAlert(
          dispatch,
          "Choosen file size or files sizes is too big please upload file less then 10MB"
        );
        return;
      }
      tempAttachments.push(file1);
    }
    setImageUploading(true);
    for (let i = 0; i < tempAttachments.length; i++) {
      let newfile = await lighterImage(tempAttachments[i]);
      tempAttachments[i] = newfile;
    }
    setImageUploading(false);
    setForm((prevForm) => ({
      ...prevForm,
      imageFiles: {
        value: [...prevForm.imageFiles.value, ...tempAttachments],
      },
    }));
    setInputChanged(true);
  };

  function goBack() {
    History.goBack();
    // if (isReviewLessonPage) {
    //   History.push({
    //     pathname: `/reviewLessonList`,
    //   });
    // } else {
    //   History.push({
    //     pathname: `/lessonList`,
    //   });
    // }
  }

  const previousPage = () => {
    // if (FuseUtils.unSavedChanges(form, initialForm)) {
    //   dispatch(
    //     Actions.openDialog({
    //       children: (
    //         <AlertDialog
    //           onSuccess={goBack}
    //           title={"Redirection Confirmation"}
    //           message={"Are you sure you want to go back without saving changes?"}
    //         />
    //       ),
    //     })
    //   );
    // } else {
    goBack();
    // }
  };

  useEffect(() => {
    let disableFlag = false;

    if (form.type.value === "group") {
      disableFlag =
        form.language.value === "" ||
        form.classType.value === "" ||
        form.title.value === "" ||
        descriptionText === "" ||
        form.title.value.length < 5 ||
        descriptionText.length < 10 ||
        form.level.value.length < 1 ||
        form.dayOfTheWeek.value === "" ||
        !form.dateRange.value ||
        (form.dateRange.value &&
          (form.dateRange.value.startDate === "" ||
            form.dateRange.value.startDate === null)) ||
        (form.dateRange.value &&
          (form.dateRange.value.endDate === "" ||
            form.dateRange.value.endDate === null)) ||
        form.startTime.value === "" ||
        form.duration.value === "" ||
        form.price.value === "" ||
        form.imageFiles.value.length < 2;
    } else if (
      form.type.value === "one-on-one" ||
      form.type.value === "children"
    ) {
      disableFlag =
        form.language.value === "" ||
        form.classType.value === "" ||
        form.title.value === "" ||
        descriptionText === "" ||
        form.title.value.length < 5 ||
        descriptionText.length < 10 ||
        form.trialClass.value === "" ||
        form.price.value === "" ||
        form.imageFiles.value.length < 2;
    } else if (form.type.value === "experience") {
      disableFlag =
        form.language.value === "" ||
        form.classType.value === "" ||
        form.title.value === "" ||
        descriptionText === "" ||
        form.title.value.length < 5 ||
        descriptionText.length < 10 ||
        form.level.value.length < 1 ||
        form.dates.value.length < 1 ||
        form.startTime.value === "" ||
        form.endTime.value === "" ||
        form.numberOfPlaces.value === "" ||
        form.price.value === "" ||
        form.imageFiles.value.length < 2;
    }
    if (form.classType.value === "face-to-face" && !disableFlag) {
      if (form.type.value === "group" || form.type.value === "experience") {
        disableFlag =
          form.city.value === "" ||
          form.postalCode.value === "" ||
          form.address.value === "" ||
          form.coordinates.value.latitude === 17.308688;
      } else {
        disableFlag = form.city.value === "";
      }
    }

    setIsDisable(disableFlag);
  }, [form, descriptionText]);

  const user = useSelector(({ EnglishCafe }) => EnglishCafe.teachers.user);
  useEffect(() => {
    const tempLanguages =
      user &&
      user.languages &&
      user.languages.map((language) => {
        return { label: language, value: language };
      });
    setLanguageOptions(tempLanguages);
  }, [user]);

  const { teacherId } = lesson;

  useEffect(() => {
    if (teacherId) {
      dispatch(getTeacherById(teacherId));
    }
  }, [dispatch, teacherId]);

  return (
    <LoadingOverlay
      active={loading || imageUploading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <MaterialIcon className="mr-4 text-20">
                      arrow_back
                    </MaterialIcon>
                    Go back
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {lessonId ? form.title.value : "New Lesson"}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">Lesson Detail</Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <div className="flex">
                  <Button
                    className="whitespace-no-wrap mr-4"
                    variant="contained"
                    onClick={() => {
                      History.replace("/teacherDetail/" + lesson.teacherId);
                    }}
                  >
                    <MaterialIcon
                      className={clsx(classes.leftIcon, classes.iconSmall)}
                    >
                      account_circle
                    </MaterialIcon>
                    Teacher Detail
                  </Button>
                  {isReviewLessonPage ? (
                    <div className="flex">
                      <Button
                        className="whitespace-no-wrap"
                        variant="contained"
                        onClick={() => {
                          dispatch(approveLesson(lessonId, History));
                        }}
                      >
                        <MaterialIcon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        >
                          visibility
                        </MaterialIcon>
                        Approve
                      </Button>
                      <Button
                        className="whitespace-no-wrap ml-4"
                        variant="contained"
                        onClick={() => {
                          dispatch(deleteLesson([lessonId], History));
                        }}
                      >
                        <MaterialIcon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        >
                          delete
                        </MaterialIcon>
                        Delete
                      </Button>
                      <Button
                        className="whitespace-no-wrap ml-4"
                        variant="contained"
                        disabled={isDisable || !inputChanged}
                        onClick={submitHandler}
                      >
                        <MaterialIcon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        >
                          save
                        </MaterialIcon>
                        Save Changes
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="whitespace-no-wrap"
                      variant="contained"
                      disabled={isDisable || !inputChanged}
                      onClick={submitHandler}
                    >
                      <MaterialIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        save
                      </MaterialIcon>
                      Save Changes
                    </Button>
                  )}
                </div>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab className={"h-64 normal-case"} label={"Lesson Detail"} />
            {!isReviewLessonPage && (
              <Tab
                className={lessonId === "new" ? "hidden" : "h-64 normal-case"}
                label={"List of Users"}
              />
            )}
            {!isReviewLessonPage && (
              <Tab
                className={lessonId === "new" ? "hidden" : "h-64 normal-case"}
                label={"Reviews"}
              />
            )}
          </Tabs>
        }
        content={
          form &&
          form.language &&
          form.language.value && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div className="flex flex-col w-full">
                  <>
                    <p className="mt-6 font-bold">Ad Type</p>
                    <div className="flex my-7">
                      <Icon
                        type={`${
                          form.classType.value === "group"
                            ? "filledgroupClassIcon"
                            : form.classType.value === "one-on-one"
                            ? "filledoneOnOneClassIcon"
                            : form.classType.value === "children"
                            ? "filledchildrenClassIcon"
                            : "filledexperienceClassIcon"
                        }`}
                      />
                    </div>
                  </>
                  <p className="font-bold text-3xl">
                    {form.classType.value === "group" && "Group Class"}
                    {form.classType.value === "one-on-one" && "One on One"}
                    {form.classType.value === "children" && "Children's Class"}
                    {form.classType.value === "experience" &&
                      "Experience Class"}
                  </p>
                  <form
                    className="flex flex-col md:flex-row"
                    // onSubmit={(event) => {
                    //   event.preventDefault();
                    //   submitHandler();
                    // }}
                  >
                    <div className="flex flex-col">
                      <div
                        className="mt-24 w-full md:customLessonFormWidth"
                        style={{ width: !isMobile && "28vw" }}
                      >
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">Language*</p>
                          <Tooltip title="¿En qué idioma impartes la clase?">
                            <div className="ml-1 self-start w-16">
                              <Icon type="hintIcon" />
                            </div>
                          </Tooltip>
                        </div>
                        <Select
                          onChange={inputHandler}
                          name="language"
                          options={languageOptions}
                          value={form && form.language && form.language.value}
                        />
                      </div>
                      <div
                        className="mt-24 w-full md:customLessonFormWidth"
                        style={{ width: !isMobile && "28vw", zIndex: "0" }}
                      >
                        <div className="flex items-center">
                          <p className="font-bold text-xs">Class Type*</p>
                        </div>
                        <RadioGroup
                          aria-label="classType"
                          name="classType"
                          row
                          defaultValue={
                            form && form.classType && form.classType.value
                              ? form.classType.value
                              : ""
                          }
                          onChange={(event) => {
                            inputHandler(event);
                          }}
                          value={
                            form && form.classType && form.classType.value
                              ? form.classType.value
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="online"
                            control={<Radio />}
                            label="Online"
                          />
                          <FormControlLabel
                            value="face-to-face"
                            control={<Radio />}
                            label="Face-to-Face"
                          />
                        </RadioGroup>
                      </div>
                      {form &&
                        form.classType &&
                        form.classType.value === "face-to-face" && (
                          <>
                            {(form &&
                              form.type &&
                              form.type.value === "group") ||
                            (form &&
                              form.type &&
                              form.type.value === "experience") ? (
                              <>
                                <div className="mt-24 ">
                                  <div className="flex items-center mb-2">
                                    <p className="font-bold text-xs">City*</p>
                                  </div>
                                  <input
                                    onChange={(event) => {
                                      locationChangeHandler(event);
                                    }}
                                    value={form && form.city && form.city.value}
                                    name="city"
                                    type="text"
                                    placeholder="Enter City"
                                    className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                                    style={{ width: !isMobile && "28vw" }}
                                  />
                                </div>

                                <div className="mt-24">
                                  <div className="flex items-center mb-2">
                                    <p className="font-bold text-xs">
                                      Postal Code*
                                    </p>
                                  </div>
                                  <input
                                    onChange={(event) => {
                                      locationChangeHandler(event);
                                    }}
                                    value={
                                      form &&
                                      form.postalCode &&
                                      form.postalCode.value
                                    }
                                    name="postalCode"
                                    type="text"
                                    placeholder="Enter Postal Code"
                                    className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                                    style={{ width: !isMobile && "28vw" }}
                                  />
                                </div>
                                <div className="mt-24">
                                  <div className="flex items-center mb-2">
                                    <p className="font-bold text-xs">
                                      Address*
                                    </p>
                                    <Tooltip title="Dinos dónde serán las clases, introduce el nombre del local">
                                      <div className="ml-1 self-start w-16">
                                        <Icon type="hintIcon" />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <input
                                    onChange={(event) => {
                                      locationChangeHandler(event);
                                    }}
                                    value={
                                      form && form.address && form.address.value
                                    }
                                    name="address"
                                    type="text"
                                    placeholder="Enter Address"
                                    className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                                    style={{ width: !isMobile && "28vw" }}
                                  />
                                </div>
                                <div className="mt-24 h-96">
                                  <div className="flex items-center mb-2">
                                    <p className="font-bold text-xs">
                                      Location on Map*
                                    </p>
                                  </div>
                                  <div
                                    classNames="w-full md:customLessonFormWidth h-96"
                                    style={{ width: !isMobile && "28vw" }}
                                  >
                                    <Map
                                      latitude={
                                        form &&
                                        form.coordinates &&
                                        form.coordinates.value &&
                                        form.coordinates.value.latitude
                                      }
                                      longitude={
                                        form &&
                                        form.coordinates &&
                                        form.coordinates.value &&
                                        form.coordinates.value.longitude
                                      }
                                      zoom={mapsZoom}
                                      isMarkerShown={true}
                                      setInputData={setForm}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="mt-24 ">
                                <div className="flex items-center mb-2">
                                  <p className="font-bold text-xs">City*</p>
                                  <Tooltip title="Ciudad en la que te encuentras">
                                    <div className="ml-1 self-start w-16">
                                      <Icon type="hintIcon" />
                                    </div>
                                  </Tooltip>
                                </div>
                                <input
                                  onChange={(event) => {
                                    inputHandler(event);
                                  }}
                                  value={form && form.city && form.city.value}
                                  name="city"
                                  type="text"
                                  placeholder="Enter City"
                                  className="bg-white w-full md:customLessonFormWidth p-4 rounded border border-border-gray outline-none"
                                  style={{ width: !isMobile && "28vw" }}
                                />
                              </div>
                            )}
                          </>
                        )}
                      <div className="mt-24 ">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">
                            Title* (Minimum Five Characters)
                          </p>
                          <Tooltip title="Introduce un titulo atractivo e introduce las palabras clave que te diferencien del resto">
                            <div className="ml-1 self-start w-16">
                              <Icon type="hintIcon" />
                            </div>
                          </Tooltip>
                        </div>
                        <input
                          name="title"
                          onChange={inputHandler}
                          value={form && form.title && form.title.value}
                          type="text"
                          placeholder="Enter Title"
                          className={`bg-white w-full md:customLessonFormWidth p-4 rounded border outline-none  ${
                            form &&
                            form.title &&
                            form.title.value &&
                            form.title.value !== "" &&
                            form.title.value.length < 5
                              ? "border-red"
                              : "border-grey"
                          }`}
                          style={{ width: !isMobile && "28vw" }}
                        />
                      </div>
                      <div className="mt-24 self-start md:self-stretch">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs">
                            Description* (Minimum Ten Characters)
                          </p>
                          <Tooltip title="Explica brevemente cómo son las clases, la dinámica o el perfil de alumnos">
                            <div className="ml-1 self-start w-16">
                              <Icon type="hintIcon" />
                            </div>
                          </Tooltip>
                        </div>
                        <QuillEditor
                          name="description"
                          value={
                            form && form.description && form.description.value
                          }
                          placeholder="Enter Description Here"
                          className={`bg-white w-full md:customLessonFormWidth rounded border outline-none ${
                            descriptionText !== "" &&
                            descriptionText.length < 10
                              ? "border-secondary-main"
                              : "border-border-gray"
                          }`}
                          onChange={inputHandler}
                          setDescriptionText={setDescriptionText}
                        />
                        {/* <textarea
                          name="description"
                          className={`bg-white w-full md:customLessonFormWidth p-4 rounded border  outline-none ${
                            form &&
                            form.description &&
                            form.description.value &&
                            form.description.value !== "" &&
                            form.description.value.length < 10
                              ? "border-red"
                              : "border-grey"
                          }`}
                          style={{ width: !isMobile && "28vw" }}
                          placeholder="Enter Description Here"
                          value={
                            form && form.description && form.description.value
                          }
                          rows={10}
                          onChange={inputHandler}
                        /> */}
                      </div>
                    </div>

                    <div className="md:ml-24 flex flex-col">
                      {(form && form.type && form.type.value === "group") ||
                      form.type.value === "experience" ? (
                        <>
                          <div
                            className="mt-24 w-full md:customLessonFormWidth"
                            style={{ width: !isMobile && "28vw" }}
                          >
                            <div className="flex items-center mb-2">
                              <p className="font-bold text-xs">Level*</p>
                              <Tooltip title="Elige los niveles para quienes está dirigido tu anuncio">
                                <div className="ml-1 self-start w-16">
                                  <Icon type="hintIcon" />
                                </div>
                              </Tooltip>
                            </div>
                            <Select
                              onChange={inputHandler}
                              name="level"
                              options={Level}
                              isMulti={true}
                              value={form && form.level && form.level.value}
                            />
                          </div>
                          <div
                            className="mt-24 w-full md:customLessonFormWidth"
                            style={{ width: !isMobile && "28vw" }}
                          >
                            <div className="flex items-center mb-2">
                              <p className="font-bold text-xs">Start Time*</p>
                            </div>
                            <TextField
                              id="startTime"
                              name="startTime"
                              type="time"
                              className="w-full md:customLessonFormWidth"
                              style={{ width: !isMobile && "28vw" }}
                              value={
                                form &&
                                form.startTime &&
                                form.startTime.value !== "" &&
                                form.startTime.value
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300,
                              }}
                              onChange={inputHandler}
                            />
                          </div>
                          {form.type.value === "group" && (
                            <div
                              className="mt-24 w-full md:customLessonFormWidth"
                              style={{ width: !isMobile && "28vw" }}
                            >
                              <div className="flex items-center mb-2">
                                <p className="font-bold text-xs">
                                  Class Duration (Hours)*
                                </p>
                                <Tooltip
                                  title={
                                    form.type.value === "group"
                                      ? "Recomendamos clases de 2 horas"
                                      : "Indica la duración de la experiencia"
                                  }
                                >
                                  <div className="ml-1 self-start w-16">
                                    <Icon type="hintIcon" />
                                  </div>
                                </Tooltip>
                              </div>
                              <Select
                                onChange={inputHandler}
                                name="duration"
                                options={OptionNumberGenerator(2)}
                                value={
                                  form && form.duration && form.duration.value
                                }
                              />
                            </div>
                          )}
                          {form &&
                            form.type &&
                            form.type.value === "experience" && (
                              <div
                                className="mt-24 w-full md:customLessonFormWidth"
                                style={{ width: !isMobile && "28vw" }}
                              >
                                <div className="flex items-center mb-2">
                                  <p className="font-bold text-xs">End Time*</p>
                                </div>
                                <TextField
                                  id="endTime"
                                  name="endTime"
                                  type="time"
                                  className="w-full md:customLessonFormWidth"
                                  style={{ width: !isMobile && "28vw" }}
                                  value={
                                    form &&
                                    form.endTime &&
                                    form.endTime.value !== "" &&
                                    form.endTime.value
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    step: 300,
                                  }}
                                  onChange={inputHandler}
                                />
                              </div>
                            )}
                          {form && form.type && form.type.value === "group" && (
                            <div
                              className="mt-24 w-full md:customLessonFormWidth"
                              style={{ width: !isMobile && "28vw" }}
                            >
                              <div className="flex items-center mb-2">
                                <p className="font-bold text-xs">
                                  Day of the Week*
                                </p>
                                <Tooltip title="Dinos el día de la semana, recuerda que es 1 clase semanal">
                                  <div className="ml-1 self-start w-16">
                                    <Icon type="hintIcon" />
                                  </div>
                                </Tooltip>
                              </div>
                              <Select
                                onChange={inputHandler}
                                name="dayOfTheWeek"
                                options={DayOfTheWeek}
                                value={
                                  form &&
                                  form.dayOfTheWeek &&
                                  form.dayOfTheWeek.value
                                }
                              />
                            </div>
                          )}
                          <div
                            className="mt-24 w-full md:customLessonFormWidth relative"
                            style={{ width: !isMobile && "28vw" }}
                          >
                            <div className="flex items-center mb-2">
                              <p className="font-bold text-xs">Schedule*</p>
                              <Tooltip
                                title={
                                  form &&
                                  form.type &&
                                  form.type.value === "group"
                                    ? "Debes de mantenerlo durante toda la duración del curso"
                                    : "A qué hora empieza"
                                }
                              >
                                <div className="ml-1 self-start w-16">
                                  <Icon type="hintIcon" />
                                </div>
                              </Tooltip>
                            </div>
                            <div
                              className="w-full md:customLessonFormWidth"
                              style={{ width: !isMobile && "28vw" }}
                            >
                              {form &&
                              form.type &&
                              form.type.value === "group" ? (
                                <CustomDateRangePicker
                                  name="dateRange"
                                  dateRange={form && form.dateRange}
                                  onChange={dateRangeHandler}
                                />
                              ) : (
                                <MultipleDateSelector
                                  dates={form && form.dates && form.dates.value}
                                  name="dates"
                                  onChange={inputHandler}
                                />
                              )}
                            </div>
                          </div>

                          <div
                            className="mt-24 w-full md:customLessonFormWidth"
                            style={{ width: !isMobile && "28vw" }}
                          >
                            <div className="flex items-center mb-2">
                              <p className="font-bold text-xs">
                                Number of Places*
                              </p>
                              <Tooltip
                                title={
                                  form &&
                                  form.type &&
                                  form.type.value === "group"
                                    ? "Los grupos deben de ser de máximo 5 alumnos"
                                    : "Número máximo de alumnos permitidos"
                                }
                              >
                                <div className="ml-1 self-start w-16">
                                  <Icon type="hintIcon" />
                                </div>
                              </Tooltip>
                            </div>
                            {form &&
                            form.type &&
                            form.type.value === "group" ? (
                              <input
                                name="numberOfPlaces"
                                type="number"
                                disabled
                                value="5"
                                placeholder="Enter Title"
                                className="bg-border-lightGray w-full md:customLessonFormWidth p-4 rounded border  border-border-gray outline-none"
                                style={{ width: !isMobile && "28vw" }}
                              />
                            ) : (
                              <Select
                                onChange={inputHandler}
                                name="numberOfPlaces"
                                options={OptionNumberGenerator(50)}
                                value={
                                  form &&
                                  form.numberOfPlaces &&
                                  form.numberOfPlaces.value
                                }
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className="mt-24 w-full md:customLessonFormWidth"
                          style={{ width: !isMobile && "28vw" }}
                        >
                          <div className="flex items-center mb-2">
                            <p className="font-bold text-xs">
                              Free Trial Class*
                            </p>
                            <Tooltip title="Ofrece la primera clase gratis para atraer a más alumnos">
                              <div className="ml-1 self-start w-16">
                                <Icon type="hintIcon" />
                              </div>
                            </Tooltip>
                          </div>
                          <RadioGroup
                            aria-label="trialClass"
                            name="trialClass"
                            row
                            defaultValue={
                              form && form.trialClass && form.trialClass.value
                                ? form.trialClass.value
                                : ""
                            }
                            onChange={inputHandler}
                            value={
                              form && form.trialClass && form.trialClass.value
                                ? form.trialClass.value
                                : ""
                            }
                          >
                            <FormControlLabel
                              value={"yes"}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"no"}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </div>
                      )}
                      <div className="mt-24">
                        {form.type.value === "group" && (
                          <div className="flex items-center mb-2">
                            <p className="font-bold text-xs mb-2">
                              Monthly Price* (Enter Number)
                            </p>
                            <Tooltip title="Precio que pagará el alumno cada mes">
                              <div className="ml-1 self-start w-16">
                                <Icon type="hintIcon" />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {(form.type.value === "one-on-one" ||
                          form.type.value === "children") && (
                          <div className="flex items-center mb-2">
                            <p className="font-bold text-xs mb-2">
                              Price per hour (RRP)* (Enter Number)
                            </p>
                            <Tooltip title="Precio por hora">
                              <div className="ml-1 self-start w-16">
                                <Icon type="hintIcon" />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {form.type.value === "experience" && (
                          <div className="flex items-center mb-2">
                            <p className="font-bold text-xs mb-2">
                              Price for Student* (Enter Number)
                            </p>
                            <Tooltip title="Precio de la sesión">
                              <div className="ml-1 self-start w-16">
                                <Icon type="hintIcon" />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        <input
                          name="price"
                          onChange={inputHandler}
                          value={form && form.price && form.price.value}
                          min="0"
                          type="Number"
                          placeholder="Enter Price"
                          className="bg-white w-full md:customLessonFormWidth  p-4 rounded border border-border-gray outline-none"
                          style={{ width: !isMobile && "28vw" }}
                        />
                        {form && form.price && form.price.value && (
                          <p className="font-bold text-xs mt-1">
                            Amount that you will recieve.
                            {"€ " +
                              percentageValue(
                                80,
                                form && form.price && form.price.value
                              )}
                          </p>
                        )}
                        {form.type.value === "group" && (
                          <div
                            className="mt-24 w-full md:customLessonFormWidth"
                            style={{ width: !isMobile && "28vw" }}
                          >
                            <div className="flex items-center mb-2">
                              <p className="font-bold text-xs">Discount (%)*</p>
                              <Tooltip title="Consigue llenar el grupo rápidamente ofreciendo un descuento, puedes cancelarlo cuando quieras">
                                <div className="ml-1 self-start w-16">
                                  <Icon type="hintIcon" />
                                </div>
                              </Tooltip>
                            </div>
                            <Select
                              onChange={inputHandler}
                              name="discount"
                              options={Discounts}
                              value={
                                form && form.discount && form.discount.value
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="mt-24 flex flex-col items-center self-start">
                        <div className="flex items-center mb-2">
                          <p className="font-bold text-xs mb-2">
                            Add Ad Thumbnail*
                          </p>
                          <Tooltip title="Sube fotos para hacer más atractivo tu anuncio. Pueden ser fotos tuyas como profesor, con tus alumnos o del café donde das las clases. Revisaremos la calidad de las fotos antes de ser publicadas.">
                            <div className="ml-1 self-start w-16">
                              <Icon type="hintIcon" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="p-2 md:p-3 border border-dashed border-border-gray rounded-xl">
                          <input
                            type="file"
                            id="uploadImage"
                            accept="image/*"
                            className="hidden"
                            multiple
                            value={""}
                            onChange={uploadFile}
                          />
                          <label
                            htmlFor="uploadImage"
                            className="cursor-pointer"
                          >
                            <Icon classNames="w-96" type="uploadImageIcon" />
                          </label>
                        </div>
                      </div>
                      {form &&
                        form.imageFiles &&
                        form.imageFiles.value !== "" &&
                        form.imageFiles.value.length > 0 && (
                          <div
                            className="flex overflow-x-auto relative w-full md:customLessonFormWidth  mt-2 border border-dashed border-border-gray rounded-xl styledScrollbar px-2 py-2"
                            style={{ width: !isMobile && "28vw" }}
                          >
                            {form &&
                              form.imageFiles &&
                              form.imageFiles.value.map((image, index) => {
                                return (
                                  <div
                                    className="flex flex-shrink-0 w-96 h-96 relative mr-2"
                                    key={index}
                                  >
                                    <img
                                      alt="userImage"
                                      className="w-full h-full rounded-md object-cover"
                                      src={
                                        typeof image === "string"
                                          ? image
                                          : URL.createObjectURL(image)
                                      }
                                    />
                                    <Icon
                                      type="closeIconRed"
                                      classNames="absolute w-16 cursor-pointer top-2 right-2"
                                      onClick={() => {
                                        if (typeof image === "string") {
                                          let newarr =
                                            form &&
                                            form.imageFiles &&
                                            form.imageFiles.value.filter(
                                              (rm) => rm !== image
                                            );

                                          setToBeDeletedImages((prevState) => [
                                            ...prevState,
                                            image,
                                          ]);

                                          setForm((prevForm) => ({
                                            ...prevForm,
                                            imageFiles: {
                                              value: [...newarr],
                                            },
                                          }));
                                          setInputChanged(true);
                                        } else {
                                          let newarr =
                                            form &&
                                            form.imageFiles &&
                                            form.imageFiles.value.filter(
                                              (rm) => rm.name !== image.name
                                            );
                                          setForm((prevForm) => ({
                                            ...prevForm,
                                            imageFiles: {
                                              value: [...newarr],
                                            },
                                          }));
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      {/* <Button
                        className="mt-24 bg-blue-light text-white self-center"
                        type="submit"
                        color="primary"
                        disabled={isDisable || !inputChanged}
                      >
                        {lessonId ? "Update Ad" : "Create Ad"}
                      </Button> */}
                    </div>
                  </form>
                </div>
              )}
              {tabValue === 1 && <SubscribedUsersList lessonId={lesson._id} />}
              {tabValue === 2 && <LessonReviewList lessonId={lesson._id} />}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default LessonDetail;
