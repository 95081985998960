import React from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

function CustomPhoneInput({ value, onChange, inputStyle, name }) {
  const changeHandler = (phone) => {
    const event = {
      target: {
        name,
        value: phone,
      },
    };
    onChange(event);
  };
  return (
    <PhoneInput
      inputStyle={inputStyle}
      country={"es"}
      value={value}
      onChange={changeHandler}
    />
  );
}

export default CustomPhoneInput;
