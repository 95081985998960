//NEW ENDPOINTS
export const LOGIN = "/dashboard/auth/login";
export const TEACHERS = "/dashboard/teacher";
export const STUDENTS = "/dashboard/student";
export const LESSONS = "/dashboard/lesson";
export const PAYMENTS = "/dashboard/payment";

export const getUserId = (): string => {
  return localStorage.getItem("idUser");
};

export const loginUserUrl = (): string => {
  return LOGIN;
};
export const getTeachersUrl = (page, limit): string => {
  return TEACHERS + "?page=" + page + "&limit=" + limit;
};

export const getFilteredTeachersUrl = (filter): string => {
  return TEACHERS + "/filtered/?filter=" + JSON.stringify(filter);
};

export const getFilteredStudentsUrl = (filter): string => {
  return STUDENTS + "/filtered/?filter=" + JSON.stringify(filter);
};

export const getFilteredPaymentsUrl = (filter): string => {
  return PAYMENTS + "/filtered/?filter=" + JSON.stringify(filter);
};

export const getStudentsUrl = (page, limit, isStudent): string => {
  return (
    STUDENTS + "?page=" + page + "&limit=" + limit + "&isStudent=" + isStudent
  );
};

export const getStudentPaymentTransactionsUrl = (
  page,
  limit,
  userId
): string => {
  return (
    "/dashboard/studentPayments/" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&studentId=" +
    userId
  );
};

export const getLessonsUrl = (page, limit): string => {
  return LESSONS + "/?page=" + page + "&limit=" + limit;
};

export const deleteLessonUrl = (): string => {
  return LESSONS;
};

export const getLessonbyIdUrl = (lessonId): string => {
  return LESSONS + "/" + lessonId;
};

export const updateLessonUrl = (): string => {
  return LESSONS;
};

export const getStudentByIdUrl = (userId): string => {
  return STUDENTS + "/" + userId;
};

export const getTeacherByIdUrl = (userId): string => {
  return TEACHERS + "/" + userId;
};

export const updateStudentUrl = (): string => {
  return STUDENTS;
};

export const updateTeacherUrl = (): string => {
  return TEACHERS;
};

export const getStudentSubscriptionsUrl = (page, limit, userId): string => {
  return (
    "/dashboard/studentSubscriptions" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&studentId=" +
    userId
  );
};

export const getLessonSubscriptionsUrl = (page, limit, lessonId): string => {
  return (
    "/dashboard/lessonSubscriptions" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&lessonId=" +
    lessonId
  );
};

export const getLessonsbyTeacherIdUrl = (page, limit, teacherId): string => {
  return (
    "/dashboard/lessonsbyTeacherId" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&teacherId=" +
    teacherId
  );
};

export const getEnrolmentsByTeacherIdUrl = (page, limit, teacherId): string => {
  return (
    "/dashboard/enrolmentsbyTeacherId" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&teacherId=" +
    teacherId
  );
};

export const getUnApprovedLessonsUrl = (page, limit): string => {
  return "/dashboard/UnApprovedLessons?page=" + page + "&limit=" + limit;
};

export const approveLessonUrl = (): string => {
  return "/dashboard/approveLesson";
};

export const getLessonReviewsUrl = (page, limit, lessonId): string => {
  return (
    "/dashboard/lessonReviews" +
    "?page=" +
    page +
    "&limit=" +
    limit +
    "&lessonId=" +
    lessonId
  );
};

export const deleteTeacherUrl = (): string => {
  return TEACHERS;
};

export const deleteStudentUrl = (): string => {
  return STUDENTS;
};

export const removeSubscriptionUrl = (): string => {
  return "/dashboard/removeSubscription";
};
