import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageSimple } from "@fuse";
import { connect } from "react-redux";

const styles = (theme) => ({
  layoutRoot: {},
});

const HomePage = (props) => {
  const { classes } = props;
  return (
    <FusePageSimple
      classes={{ root: classes.layoutRoot }}
      // header={
      //   // <div className="p-24">
      //   //   <h4>Header</h4>
      //   // </div>
      // }
      content={
        <div className="p-24">
          <h4>ANALYTICS</h4>
          <br />
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
