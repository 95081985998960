import { StudentsActionTypes } from "app/store/redux/actionTypes";

const INITIAL_STATE: StudentState = {
  users: [],
  payments: [{}],
  totalDataCount: 0,
  loading: false,
  searchText: "",
  user: {},
  lessons: [{}],
};

interface Action {
  payload: any;
  type: string;
}

const StudentReducer = (
  state: StudentState = INITIAL_STATE,
  action: Action
): StudentState => {
  switch (action.type) {
    case StudentsActionTypes.GET_STUDENTS_START: {
      if (action.payload.currentPage === 1) {
        state.users = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case StudentsActionTypes.GET_STUDENTS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.users = [];
        state.totalDataCount = 0;
        return {
          ...state,
          users: action.payload.students,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          users: [...state.users, ...action.payload.students],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case StudentsActionTypes.GET_STUDENTS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StudentsActionTypes.GET_STUDENT_PAYMENTS_START: {
      if (action.payload.currentPage === 1) {
        state.payments = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: true };
      }
    }
    case StudentsActionTypes.GET_STUDENT_PAYMENTS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.payments = [];
        state.totalDataCount = 0;
        return {
          ...state,
          payments: action.payload.payments,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          payments: [...state.payments, ...action.payload.payments],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case StudentsActionTypes.GET_STUDENT_PAYMENTS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StudentsActionTypes.SET_STUDENT_SEARCH_TEXT: {
      return {
        ...state,
        loading: false,
        searchText: action.payload.searchText,
      };
    }

    case StudentsActionTypes.SET_STUDENT_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case StudentsActionTypes.GET_STUDENT_START: {
      return { ...state, loading: true };
    }
    case StudentsActionTypes.GET_STUDENT_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }
    case StudentsActionTypes.GET_STUDENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StudentsActionTypes.UPDATE_STUDENT_START: {
      return { ...state, loading: true };
    }
    case StudentsActionTypes.UPDATE_STUDENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case StudentsActionTypes.UPDATE_STUDENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case StudentsActionTypes.GET_STUDENT_SUBSCRIPTIONS_START: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        state.totalDataCount = 0;
        return { ...state, loading: true };
      } else {
        return { ...state, loading: false };
      }
    }
    case StudentsActionTypes.GET_STUDENT_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.currentPage === 1) {
        state.lessons = [];
        return {
          ...state,
          lessons: action.payload.lessons,
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      } else {
        return {
          ...state,
          lessons: [...state.lessons, ...action.payload.lessons],
          totalDataCount: action.payload.totalDataCount,
          loading: false,
        };
      }
    }
    case StudentsActionTypes.GET_STUDENT_SUBSCRIPTIONS_FAIL: {
      return { ...state, loading: false };
    }

    case StudentsActionTypes.DELETE_STUDENT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case StudentsActionTypes.DELETE_STUDENT_SUCCESS: {
      const users = state.users.filter(
        (item) => !action.payload.studentIds.includes(item._id)
      );
      const totalDeleted = state.users.length - users.length;
      return {
        ...state,
        users,
        totalDataCount: state.totalDataCount - totalDeleted,
        loading: false,
      };
    }

    case StudentsActionTypes.DELETE_STUDENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default StudentReducer;
