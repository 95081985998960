import AdvancedSearch from "./AdvancedSearch";
export const AdvancedSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/advanced-search",
      component: AdvancedSearch,
    },
  ],
};
