import React from "react";
import { TableCell } from "@material-ui/core";

const SuccessAndDangerCell = ({ n, obj }) => {
  return (
    <TableCell component="th" scope="row">
      {obj.showSuccessAndDanger ? (
        <div className={n[obj.id] > 0 ? "text-green" : "text-red"}>
          {n[obj.id]}
        </div>
      ) : typeof obj.id == "object" && obj.id.length > 1 ? (
        obj.id.map((x, i) => {
          let value = i > 0 ? " " + n[x] : n[x];
          return value;
        })
      ) : (
        n[obj.id]
      )}
    </TableCell>
  );
};

export default SuccessAndDangerCell;
