import {
  Button,
  Card,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import { categories, Countries_List, Provinces } from "app/helpers/data";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredTeachers } from "app/store/teachers/TeacherActions";
import ListTable from "app/main/common/List/ListTable";
import { utils, writeFile } from "xlsx";
import { errorAlert } from "app/helpers/alerts";
// import Select from "app/main/common/Select";

function Teachers() {
  const dispatch = useDispatch();

  const { loading, users } = useSelector(
    ({ EnglishCafe }) => EnglishCafe.teachers
  );

  const exportDataHandler = () => {};

  const [form, setForm] = useState({
    language: "",
    lessonType: "",
    province: "",
    nationality: "",
  });

  // useEffect(() => {
  //   dispatch(resetTeachers());
  // }, [dispatch]);

  useEffect(() => {
    const payload = {};
    if (form && form.nationality) {
      payload["nationality"] = form.nationality;
    }
    if (form && form.province) {
      payload["province"] = form.province;
    }
    if (form && form.lessonType) {
      payload["lessonType"] = form.lessonType;
    }
    if (form && form.language) {
      payload["language"] = form.language;
    }
    dispatch(getFilteredTeachers(payload));
  }, [dispatch, form]);

  const inputHandler = (event) => {
    const prevForm = JSON.parse(JSON.stringify(form));

    prevForm[event.target.name] = event.target.value;
    setForm(prevForm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (users && users.length > 0) {
      const worksheet = utils.json_to_sheet(users);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "FilteredTeachers.xlsx");
    } else {
      errorAlert(dispatch, "No result Found");
    }
  };

  const handlePageChange = (page) => {};

  const handleClick = (item) => {
    History.push("/lessonDetail/" + item._id);
  };

  const headerRows = {
    data: [
      {
        id: "name",
        align: "left",
        disablePadding: false,
        label: "Name",
        sort: true,
        type: "text",
      },
      {
        id: "surname",
        align: "left",
        disablePadding: false,
        label: "Surname",
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: "Email",
        sort: true,
        type: "text",
      },
      {
        id: "province",
        align: "left",
        disablePadding: false,
        label: "Province",
        sort: true,
        type: "text",
      },
      {
        id: "nationality",
        align: "left",
        disablePadding: false,
        label: "Nationality",
        sort: true,
        type: "text",
      },
      {
        id: "languages",
        align: "left",
        disablePadding: false,
        label: "Languages",
        sort: true,
        type: "text",
      },
    ],

    checkbox: false,
    // clickable: true,
    locationSearch: false,
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <form className="flex flex-col px-12 py-20" onSubmit={handleSubmit}>
        <Card className="flex flex-col py-10 px-10">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.nationality}
                onChange={inputHandler}
                label="Nationality"
                name="nationality"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Countries_List.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.province}
                onChange={inputHandler}
                label="Province"
                name="province"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Provinces.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                select
                value={form && form.lessonType}
                onChange={inputHandler}
                label="Lesson Type"
                name="lessonType"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {categories.map((item) => (
                  <MenuItem key={item} value={item} className="capitalize">
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl
              variant="outlined"
              className="w-1/4 mb-6 md:mb-0 md:ml-6"
            >
              <TextField
                variant={"outlined"}
                value={form && form.language}
                onChange={(event) =>
                  inputHandler({
                    target: { name: "language", value: event.target.value },
                  })
                }
                label="Language"
                name="language"
              />
            </FormControl>
            <Button
              type="submit"
              className="ml-6 bg-blue text-white self-center hover:bg-black"
              onClick={exportDataHandler}
            >
              Export
            </Button>
          </div>
        </Card>
      </form>
      {users.length > 0 && (
        <ListTable
          handleClick={handleClick}
          headerRows={headerRows}
          data={users}
          searchText={""}
          handlePageChange={handlePageChange}
          dataLength={users.length}
        />
      )}
    </LoadingOverlay>
  );
}

export default Teachers;
