import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import history from "@history";

import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import {
  deleteLesson,
  getUnApprovedLessons,
  setUserSearchText,
} from "app/store/lessons/LessonActions";

const StudentList = () => {
  const dispatch = useDispatch();

  const {
    lessons: lessonList,
    totalDataCount,
    searchText,
    loading,
  } = useSelector(({ EnglishCafe }) => EnglishCafe.lessons);
  useEffect(() => {
    dispatch(getUnApprovedLessons(1, 10));
  }, [dispatch]);
  const onHandleDelete = (selected) => {
    dispatch(deleteLesson(selected));
  };
  const setSearchText = (ev) => {
    dispatch(setUserSearchText(ev));
  };

  const handlePageChange = (page) => {
    // dispatch(getUnApprovedLessons(page, 10));
  };

  const handleClick = (item) => {
    history.push("/reviewLessonDetail/" + item._id);
  };

  const headerRows = {
    data: [
      {
        id: "title",
        align: "left",
        disablePadding: false,
        label: "Title",
        sort: true,
        type: "text",
      },
      {
        id: "type",
        align: "left",
        disablePadding: false,
        label: "Type",
        sort: true,
        type: "text",
      },
      {
        id: "teacherName",
        align: "left",
        disablePadding: false,
        label: "Teacher Name",
        sort: true,
        type: "text",
      },
      {
        id: "language",
        align: "left",
        disablePadding: false,
        label: "Language",
        sort: true,
        type: "text",
      },
      {
        id: "classType",
        align: "left",
        disablePadding: false,
        label: "ClassType",
        sort: true,
        type: "text",
      },
      {
        id: "city",
        align: "left",
        disablePadding: false,
        label: "City",
        sort: true,
        type: "text",
      },
      {
        id: "level",
        align: "left",
        disablePadding: false,
        label: "Level",
        sort: true,
        type: "text",
      },
      {
        id: "price",
        align: "left",
        disablePadding: false,
        label: "Price",
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: "Created At",
        sort: true,
        type: "date",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["title", "type", "language", "classType", "city", "teacherName"],
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <ListHeader
            title="Lessons"
            setSearchText={setSearchText}
            searchType={"lessons"}
          />
        }
        content={
          <ListTable
            handleClick={handleClick}
            handleDelete={onHandleDelete}
            headerRows={headerRows}
            data={lessonList}
            searchText={searchText}
            handlePageChange={handlePageChange}
            dataLength={totalDataCount}
          />
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default StudentList;
