const navigationConfig = [
  {
    id: "Menu",
    title: "Menu",
    type: "group",
    icon: "apps",
    children: [
      // {
      //   id: "Dashboard",
      //   title: "Home",
      //   type: "item",
      //   icon: "whatshot",
      //   url: "/home",
      // },
      {
        id: "Teacher",
        title: "Teacher",
        type: "item",
        icon: "person",
        url: "/teacherlist",
      },
      {
        id: "Registered",
        title: "Registered",
        type: "item",
        icon: "person",
        url: "/registeredList",
      },
      {
        id: "Student",
        title: "Student",
        type: "item",
        icon: "person",
        url: "/studentlist",
      },
      {
        id: "Lesson",
        title: "Lesson",
        type: "item",
        icon: "library_books",
        url: "/lessonlist",
      },
      {
        id: "ReviewLessons",
        title: "Review Lessons",
        type: "item",
        icon: "rate_review",
        url: "/reviewLessonList",
      },
      {
        id: "AdvancedSearch",
        title: "Advanced-Search",
        type: "item",
        icon: "search",
        url: "/advanced-search",
      },
    ],
  },
];

export default navigationConfig;
