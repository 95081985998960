import RegisteredList from "./RegisteredList";
export const RegisteredPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/registeredList",
      component: RegisteredList,
    },
  ],
};
