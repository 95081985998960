import React from "react";
import { TableCell } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  red: {
    color: "red",
    fontWeight: "bold",
  },
}));

const PriceReduceCell = (props) => {
  const currency = useSelector(
    ({ EnglishCafe }) => EnglishCafe.auth.user.currency
  );
  const classes = useStyles(props);
  const { n } = props;
  return (
    <TableCell className="truncate" component="th" scope="row">
      {n.hidePrice ? (
        <font>{"DISABLED_PRICE"}</font>
      ) : (
        <font>
          {n.reducedPrice && (
            <font className={clsx(classes.red)}>
              {n.reducedPrice} {currency}
            </font>
          )}{" "}
          {n.originalPrice && (
            <strike>
              {n.originalPrice} {currency}{" "}
            </strike>
          )}
        </font>
      )}
    </TableCell>
  );
};

export default PriceReduceCell;
