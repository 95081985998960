import React from "react";
import { TableCell } from "@material-ui/core";

export default function MenuTypeCell({ n, obj }) {
  return (
    <TableCell component="th" scope="row">
      {n[obj.id] ? "True" : "False"}
    </TableCell>
  );
}
