import { combineReducers } from "redux";
import auth from "app/store/auth/AuthReducer";
import teachers from "app/store/teachers/TeacherReducer";
import students from "app/store/students/StudentReducer";
import lessons from "app/store/lessons/LessonReducer";
import payments from "app/store/payments/PaymentReducer";

const EnglishCafeReducers = combineReducers({
  auth,
  teachers,
  students,
  lessons,
  payments,
});

export default EnglishCafeReducers;
