import TeacherList from "./TeacherList";
import TeacherDetail from "./TeacherDetail";
export const TeacherPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/teacherlist",
      component: TeacherList,
    },
    {
      path: "/teacherDetail/:teacherId",
      component: TeacherDetail,
    },
  ],
};
