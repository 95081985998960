import LessonList from "./LessonList";
import LessonDetail from "./LessonDetail";
export const LessonPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/lessonlist",
      component: LessonList,
    },
    {
      path: "/lessonDetail/:lessonId",
      component: LessonDetail,
    },
  ],
};
